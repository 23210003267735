import { Prisma } from "@prisma/client"
import { convertDateString, isoDate } from "~/utils/datetime"
import { isNullish } from "~/utils/values"

// Export methods from Prisma
const { empty, join: templateJoin, raw, sql } = Prisma

export function join(
  elements: Array<Prisma.Sql | string | number>,
  conjunction: string
) {
  const filteredElements = elements.filter((e) => e !== Prisma.empty)
  if (!filteredElements.length) {
    return empty
  }
  return templateJoin(filteredElements, conjunction)
}

export function fullMatch(value: string) {
  return `%${value}%`
}

export function parenthesis(statement: Prisma.Sql): Prisma.Sql {
  return join([sql`(`, statement, sql`)`], "")
}

export function normaliseToArray(rawValue: any | any[]) {
  if (Array.isArray(rawValue)) {
    return rawValue
  }
  return rawValue ? [rawValue] : []
}

export function limitOffset(limit = 25, offset = 0): Prisma.Sql {
  return sql`LIMIT ${limit} OFFSET ${offset}`
}

export function selectDistinctOn(columns: string[]) {
  return join(
    [sql`SELECT DISTINCT ON (`, join(columns.map(raw), ", "), sql`)`],
    " "
  )
}

export function where(clause: Prisma.Sql) {
  if (isEmptySql(clause)) return empty

  return join([sql`WHERE`, clause], " ")
}

export function withSql(clause: Prisma.Sql) {
  if (isEmptySql(clause)) return empty

  return join([sql`WITH`, clause], " ")
}

export function orderBy(
  ordering: {
    column: string
    direction: "asc" | "desc"
  }[]
): Prisma.Sql {
  const clauses = ordering.map(({ column, direction }) => {
    return sql`${raw(column)} ${raw(
      (direction ?? "asc").toUpperCase()
    )} NULLS LAST`
  })
  return join([sql`ORDER BY`, join(clauses, ", ")], " ")
}

export function groupBy(identifiers: Array<string | number>) {
  return join(
    [
      sql`GROUP BY`,
      join(
        identifiers.map((i) => {
          return sql`${raw(String(i))}`
        }),
        ", "
      ),
    ],
    " "
  )
}

export function values(data: any[][]) {
  const rows = data.map((d) => sql`(${join(d, ",")})`)
  return join([sql`VALUES`, join(rows, ", ")], " ")
}

export function nameSearchStatement(
  table: string,
  value: string | null | undefined,
  notSearch: boolean
): Prisma.Sql {
  return value
    ? sql`${raw(table)} @@ ${raw(
        notSearch ? "!!" : ""
      )} websearch_to_tsquery('simple', ${value})`
    : empty
}

/**
 * Make sure that the last element of a period separated identifier is quoted.
 * 'table_name' becomes '"table_name"'
 * 'schema.table_name' becomes 'schema."table_name"'
 *
 * @param i: The identifier to be processed
 */
export function quoteIdentifier(i: string | number) {
  if (typeof i === "string")
    if (i.includes(".")) {
      const parts = i.split(".")
      return `${parts.slice(0, -1).join(".")}."${parts.slice(-1)}"`
    } else {
      return `"${i}"`
    }
  return i
}

export function postgresDateString(datestring: string): string {
  return isoDate(convertDateString(datestring))
}

export const bigIntToStringJSON = (key: string, value: any) => {
  return typeof value === "bigint" ? value.toString() : value
}

export function isEmptySql(statement: Prisma.Sql) {
  return (
    JSON.stringify(statement, bigIntToStringJSON) == JSON.stringify(raw(""))
  )
}

type ReplaceBigIntWithNumber<T> = T extends undefined
  ? undefined
  : {
      [K in keyof T]: T[K] extends bigint ? string : T[K]
    }

export function serializableJSON<T>(value: T): ReplaceBigIntWithNumber<T> {
  if (isNullish(value)) return value as ReplaceBigIntWithNumber<T>

  return JSON.parse(JSON.stringify(value, bigIntToStringJSON))
}
