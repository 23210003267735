import { ZodFirstPartySchemaTypes, ZodObject } from "zod"

import { Box, Image } from "@chakra-ui/react"
import {
  InvestorHighlight,
  Queries,
  QueriesByUser,
  SpecterProducts,
} from "@prisma/client"
import { ReactNode } from "react"
import { IconType } from "react-icons"
import {
  HiOutlineArrowTrendingUp,
  HiOutlineCurrencyDollar,
  HiOutlineFire,
  HiOutlineRocketLaunch,
  HiOutlineSparkles,
  HiOutlineStar,
  HiOutlineTrophy,
} from "react-icons/hi2"
import { LuDna } from "react-icons/lu"
import {
  COMPANY_HIGHLIGHTS,
  COMPANY_HIGHLIGHTS_GROUPS,
  COMPANY_HIGHLIGHTS_PROPS,
  INVESTOR_HIGHLIGHTS_PROPS,
  PEOPLE_HIGHLIGHTS,
  PEOPLE_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import { isOneOf } from "~/utils/isOneOf"
import { DATE_RANGE_OPTIONS } from "~/utils/signal"
import {
  acquisitionAdvancedFiltersSchema,
  AcquisitionDBFilters,
  acquisitionFiltersValidation,
} from "./acquisition"
import {
  companyAdvancedFiltersSchema,
  CompanySignalFilters,
  companySignalFiltersValidation,
} from "./company"
import {
  fundingRoundsAdvancedFiltersSchema,
  FundingRoundsDBFilters,
  fundingRoundsFiltersValidation,
} from "./funding_rounds"
import {
  investorsAdvancedFiltersSchema,
  InvestorsDBFilters,
  investorsFiltersValidation,
} from "./investors"
import {
  ipoAdvancedFiltersSchema,
  IpoDBFilters,
  ipoFiltersValidation,
} from "./ipo"
import { peopleAdvancedFiltersSchema, peopleFiltersValidation } from "./people"
import {
  strategicAdvancedFiltersSchema,
  StrategicSignalFilters,
  strategicSignalFiltersValidation,
} from "./stratintel"
import {
  talentAdvancedFiltersSchema,
  TalentSignalFilters,
  talentSignalFiltersValidation,
} from "./talent"

import { keys } from "ramda"
import { PiGhost, PiSuitcaseSimple, PiTree } from "react-icons/pi"

export type SignalFilters = {
  company: CompanySignalFilters
  talent: TalentSignalFilters
  stratintel: StrategicSignalFilters
  investors: InvestorsDBFilters
  fundingRounds: FundingRoundsDBFilters
  acquisition: AcquisitionDBFilters
  ipo: IpoDBFilters
  people: TalentSignalFilters
}

export type FiltersSchema = ZodObject<{
  [itemKey: string]: FiltersTabSchema
}>

export type FiltersTabSchema = ZodObject<{
  [sectionKey: string]: FiltersSectionSchema
}>

export type FiltersSectionSchema = ZodObject<{
  [itemKey: string]: ZodFirstPartySchemaTypes
}>

export const PRODUCT_FILTERS_SCHEMA = {
  [SpecterProducts.company]: companyAdvancedFiltersSchema,
  [SpecterProducts.talent]: talentAdvancedFiltersSchema,
  [SpecterProducts.stratintel]: strategicAdvancedFiltersSchema,
  [SpecterProducts.investors]: investorsAdvancedFiltersSchema,
  [SpecterProducts.fundingRounds]: fundingRoundsAdvancedFiltersSchema,
  [SpecterProducts.acquisition]: acquisitionAdvancedFiltersSchema,
  [SpecterProducts.ipo]: ipoAdvancedFiltersSchema,
  [SpecterProducts.people]: peopleAdvancedFiltersSchema(),
} as const

export const PRODUCT_SIGNAL_FILTERS_VALIDATION = {
  [SpecterProducts.company]: companySignalFiltersValidation,
  [SpecterProducts.talent]: talentSignalFiltersValidation,
  [SpecterProducts.stratintel]: strategicSignalFiltersValidation,
  [SpecterProducts.investors]: investorsFiltersValidation,
  [SpecterProducts.fundingRounds]: fundingRoundsFiltersValidation,
  [SpecterProducts.acquisition]: acquisitionFiltersValidation,
  [SpecterProducts.ipo]: ipoFiltersValidation,
  [SpecterProducts.people]: peopleFiltersValidation,
} as const

export const PRODUCT_ROUTE_NAMES: Record<string, SpecterProducts> = {
  company: SpecterProducts.company,
  talent: SpecterProducts.talent,
  strategic: SpecterProducts.stratintel,
  investors: SpecterProducts.investors,
  "funding-rounds": SpecterProducts.fundingRounds,
  acquisition: SpecterProducts.acquisition,
  ipo: SpecterProducts.ipo,
  people: SpecterProducts.people,
} as const

export function mapRouteNameToProduct(
  productRouteName: string
): SpecterProducts {
  return PRODUCT_ROUTE_NAMES[productRouteName]
}

export function mapProductsToRouteName(product: SpecterProducts) {
  switch (product) {
    case SpecterProducts.stratintel:
      return "strategic"
    case SpecterProducts.fundingRounds:
      return "funding-rounds"
    default:
      return product
  }
}

//
// * SUGGESTED SEARCHES
//

export type SuggestedSearchSchema = {
  query: Queries["query"]
  sort?: QueriesByUser["sort"]
  searchId?: number
}

export type SuggestedSearchMenuItemSchema = SuggestedSearchSchema & {
  label: ReactNode
}

export const PRODUCT_SUGGESTED_SEARCHES: Record<
  SpecterProducts,
  Record<
    string,
    {
      icon?: IconType
      searches:
        | SuggestedSearchMenuItemSchema[]
        | SuggestedSearchSchema
        | (() => Promise<SuggestedSearchMenuItemSchema[]>)
    }
  >
> = {
  [SpecterProducts.people]: {},
  [SpecterProducts.company]: {
    "Growth Highlights": {
      icon: HiOutlineArrowTrendingUp,
      searches: COMPANY_HIGHLIGHTS
        // Don't show funding and investors highlights
        .filter(
          (key) =>
            !isOneOf(COMPANY_HIGHLIGHTS_PROPS[key].group, [
              "funding",
              "investors",
            ])
        )
        .map((option) => {
          const { group, label } = COMPANY_HIGHLIGHTS_PROPS[option]
          const { colorScheme } = COMPANY_HIGHLIGHTS_GROUPS[group]
          return {
            label: (
              <>
                <Box
                  boxSize={2}
                  bgColor={`${colorScheme}.400`}
                  borderRadius="full"
                />
                {label}
              </>
            ),
            query: {
              Highlights: ["OR", [option]],
            },
          }
        }),
    },
    "Funding Highlights": {
      icon: HiOutlineCurrencyDollar,
      searches: COMPANY_HIGHLIGHTS
        // Only show funding highlights
        .filter((key) =>
          isOneOf(COMPANY_HIGHLIGHTS_PROPS[key].group, ["funding", "investors"])
        )
        .map((option) => {
          const { group, label } = COMPANY_HIGHLIGHTS_PROPS[option]
          const { colorScheme } = COMPANY_HIGHLIGHTS_GROUPS[group]
          return {
            label: (
              <>
                <Box
                  boxSize={2}
                  bgColor={`${colorScheme}.400`}
                  borderRadius="full"
                />
                {label}
              </>
            ),
            query: {
              Highlights: ["OR", [option]],
            },
          }
        }),
    },
    "Founders Highlights": {
      icon: HiOutlineRocketLaunch,
      searches: PEOPLE_HIGHLIGHTS.map((option) => {
        const { label, colorScheme } = PEOPLE_HIGHLIGHTS_PROPS[option]
        return {
          label: (
            <>
              <Box
                boxSize={2}
                bgColor={`${colorScheme}.400`}
                borderRadius="full"
              />
              {label}
            </>
          ),
          query: {
            FounderHighlights: ["OR", [option]],
          },
        }
      }),
    },
    Awards: {
      icon: HiOutlineTrophy,
      searches: async () => {
        const options: { value: string; logoUrl: string }[] = await fetch(
          `/api/input-options/company/awards`
        ).then((result) => result.json())

        return options.map((option) => ({
          label: (
            <>
              <Image src={option.logoUrl} boxSize="12px" mr={1.5} />
              {option.value}
            </>
          ),
          query: {
            Awards: ["OR", [option.value]],
          },
        }))
      },
    },
  },
  [SpecterProducts.talent]: {
    "New Founders": {
      icon: HiOutlineRocketLaunch,
      searches: [
        {
          label: "New Founders - North America",
          query: {
            SignalType: ["New Company"],
            Location: [
              // North America
              '["216454257090494464:216735732067205120","360569445166350336:361132395119771648","361413870096482304:362821244980035584","363665669910167552:364228619863588864","364791569817010176:366198944700563456","366480419677274112:366761894653984768","367324844607406080:368169269537538048","368450744514248704:369295169444380672","369576644421091328:369858119397801984","370139594374512640:370702544327933952","371546969258065920:372672869164908544","373517294095040512:373798769071751168","648799821318062080:649081296294772736"]',
            ],
          },
        },
        {
          label: "New Founders - Europe + Israel",
          query: {
            SignalType: ["New Company"],
            Location: [
              // Europe
              '["144959613005987840:145241087982698496","147211412819673088:147492887796383744","288511851128422400:295548725546188800","295830200522899456:298363475313295360","298644950290006016:303992974847508480","437130638831648768:437412113808359424","576742227280134144:577023702256844800"]',
              // Israel
              "295548725546188800:295830200522899455",
            ],
          },
        },
        {
          label: "New Founders - Nordics",
          query: {
            SignalType: ["New Company"],
            // Nordics
            Location: [
              '["288511851128422400:288793326105133056","291608075872239616:291889550848950272","292452500802371584:292733975779082240","293859875685924864:294141350662635520","294704300616056832:294985775592767488","299489375220137984:299770850196848640","302022650010533888:302585599963955200"]',
            ],
          },
        },
        {
          label: "New Founders - CEE",
          query: {
            // CEE
            Location: [
              '["288793326105133056:289074801081843711","289356276058554368:289637751035265023","289637751035265024:289919226011975679","290200700988686336:290482175965396991","290482175965396992:290763650942107647","290763650942107648:291045125918818303","291326600895528960:291608075872239615","291889550848950272:292171025825660927","294422825639346176:294704300616056831","296393150476320768:296674625453031423","296674625453031424:296956100429742079","297237575406452736:297519050383163391","297800525359874048:298082000336584703","298644950290006016:298926425266716671","299207900243427328:299489375220137983","299770850196848640:300052325173559295","300333800150269952:300615275126980607","300896750103691264:301178225080401919","301178225080401920:301459700057112575","301459700057112576:301741175033823231","303148549917376512:303430024894087167"]',
            ],
            SignalType: ["New Company"],
          },
        },
        {
          label: "New Founders - Oceania",
          query: {
            SignalType: ["New Company"],
            Location: [
              // Oceania
              '["146648462866251776:147211412819673088","504684633242206208:507499383009312768","507780857986023424:510032657799708672","510314132776419328:512284457613393920"]',
            ],
          },
        },
        {
          label: "New Founders - Emerging Markets",
          query: {
            SignalType: ["New Company"],
            Location: [
              // Africa
              '["72339069014638592:82190693199511552","82753643152932864:86694292826882048","298363475313295360:298644950290006016","432627039204278272:432908514180988928","433189989157699584:433471464134410240","434878839017963520:435723263948095488","436849163854938112:437130638831648768"]',
              // Asia
              '["144396663052566528:155092712167571456","291045125918818304:291326600895528960","295548725546188800:295830200522899456","432908514180988928:433189989157699584","433471464134410240:434878839017963520","435723263948095488:436849163854938112","437130638831648768:437975063761780736"]',
              // LATAM
              '["360569449461317633:374643301376065566"]',
              // MENA
              '["291045125918818304:291326600895528960","295548725546188800:295830200522899456","432627039204278272:435160313994674176","435441788971384832:437975063761780736"]',
              // Russia
              "576742227280134144:577023702256844799",
            ],
          },
        },
      ],
    },
    "New Stealth Startups": {
      icon: PiGhost,
      searches: [
        {
          label: "New Founders in Stealth",
          query: {
            SignalStatus: ["Stealth"],
            SignalType: ["New Company"],
            TalentLatestSignal: "Yes",
          },
        },
        {
          label: "New Roles in Stealth",
          query: {
            SignalStatus: ["Stealth"],
            SignalType: ["New Role"],
            TalentLatestSignal: "Yes",
          },
        },
        {
          label: "Founders coming Out of Stealth",
          query: {
            SignalStatus: ["Out of Stealth"],
            TalentLatestSignal: "Yes",
          },
        },
      ],
    },
    "Spinout Founders": {
      icon: LuDna,
      searches: [
        {
          label: "Founders ex-Unicorns 🦄",
          query: {
            SignalDate: DATE_RANGE_OPTIONS.THIS_WEEK,
            SignalType: ["New Company"],
            Companies: [
              "Past",
              [
                "OR",
                [
                  "5e3b8cef137e998b5ae0c000", // bytedance.com
                  "5e3bc21f00c8f4c966aafede", // spacex.com
                  "5e3bc2b700c8f4c966ad2bbd", // stripe.com
                  "5e3b9368137e998b5aeb1d95", // canva.com
                  "5e3a8f1a040ca7b0c6f05698", // revolut.com
                  "5e3ba18600c8f4c9668c33eb", // epicgames.com
                  "5e3a8f19040ca7b0c6f03ddd", // databricks.com
                  "5e3b9a89137e998b5aed1190", // fanatics.com
                  "5e3bc07300c8f4c966a4f552", // openai.com
                  "5e3a8f1c040ca7b0c6f0865b", // chime.com
                  "5e3bc3b700c8f4c966b0ae87", // xiaohongshu.com
                  "655e111e5fcaf92a8bb00fb2", // jtexpress.sg
                  "5e3bc5e900c8f4c966b387bf", // miro.com
                  "5e3bc3ef00c8f4c966b18798", // yuanfudao.com
                  "5e3b91cb137e998b5ae93205", // dji.com
                  "5f76ba87a2734681ce2c060d", // discord.com
                  "5e3a8f19040ca7b0c6f03f6b", // gopuff.com
                  "5e3b8daa137e998b5ae236a7", // yuanqisenlin.com
                  "5e3a8f1a040ca7b0c6f052bf", // ripple.com
                  "5e3b8cc7137e998b5ae0535b", // blockchain.com
                  "5e3a8f19040ca7b0c6f03e84", // plaid.com
                  "5e3a8f19040ca7b0c6f03c05", // opensea.io
                  "5e3a8f45040ca7b0c6f0d1a0", // celonis.com
                  "5e3a8f1b040ca7b0c6f06111", // grammarly.com
                  "5e3a8f19040ca7b0c6f03948", // devoted.com
                  "5e3a8f19040ca7b0c6f0382c", // faire.com
                  "5e3b9189137e998b5ae8c655", // brex.com
                  "5e3a8f19040ca7b0c6f035a9", // instacart.com
                  "63eb90c41e6a68488a04a251", // instacart.com
                  "5e3a8f75040ca7b0c6f207a3", // bitmain.com
                  "61e69637f2bbe0f601924dbd", // biosplice.com
                  "61acc8d9d0efcf20c6bf9f36", // goodleap.com
                  "606f1da86a1d5452786302dd", // xsyxsc.com
                  "6270fedd4b7ad6a07cbd0326", // deel.com
                  "5e3a8f1b040ca7b0c6f05db6", // airtable.com
                  "5e3a8f19040ca7b0c6f0396d", // byjus.com
                  "5e3bc3e400c8f4c966b161bf", // zongmutech.com
                  "5e3a8f19040ca7b0c6f03082", // rippling.com
                  "5e3ba44900c8f4c9668fb29a", // globalswitch.com
                  "5e3a8f19040ca7b0c6f0308e", // checkout.com
                  "5e3a8f4c040ca7b0c6f18ac3", // bolt.com
                  "60e9575c7d096fb914465401", // alchemy.com
                  "62c2c6740405ea8a58c3f843", // huolala.cn
                  "601b8b254ddab8d8bfaabde3", // wiz.io
                  "5e3b65e8ab6348f63cd9e60c", // gusto.com
                  "5e3bbccd00c8f4c9669a624c", // guazi.com
                  "5e3bc0df00c8f4c966a67268", // reddit.com
                  "5e3b65e8ab6348f63cd9e534", // talkdesk.com
                  "5e3a8f1a040ca7b0c6f04dc9", // notion.so
                  "60c211395e744335aaf3e429", // thrasio.com
                  "5e3b920c137e998b5ae9a816", // dcg.co
                  "5e3bbc1e00c8f4c96698172c", // kucoin.com
                  "5e3a8f19040ca7b0c6f030de", // servicetitan.com
                  "5e3bbe3d00c8f4c9669e3b68", // heytea.com
                  "5e3a8f45040ca7b0c6f0ce7e", // n26.com
                  "5e3a8f19040ca7b0c6f034ac", // klaviyo.com
                  "63eb90bd1e6a68488a041c5b", // navan.com
                  "5e3a8f19040ca7b0c6f03838", // northvolt.com
                  "5e3bbfb900c8f4c966a2d687", // tanium.com
                  "5e3bbead00c8f4c9669fa7a9", // nianticlabs.com
                  "5e3bc0f900c8f4c966a6def8", // oyorooms.com
                  "63eb90bd1e6a68488a041dc5", // getir.com
                  "5e3a8f1a040ca7b0c6f04932", // rapyd.net
                  "5e3ba37600c8f4c9668e7f71", // kavak.com
                  "5e3a8f1a040ca7b0c6f048e1", // nuro.ai
                  "5e3a8f19040ca7b0c6f03755", // chainalysis.com
                  "5e3a8f1a040ca7b0c6f05769", // pony.ai
                  "5e3a8f1a040ca7b0c6f05bba", // personio.com
                  "5e3b65e9ab6348f63cd9f744", // personio.com
                  "606ef9456a1d5452781538c1", // sumup.co.uk
                  "650d747a538115f141cb701c", // anduril.com
                  "5e3a8f1b040ca7b0c6f06ffd", // anduril.com
                  "5e3a8f4c040ca7b0c6f18ac3", // bolt.com
                  "5e3a8f48040ca7b0c6f11f9a", // lacework.com
                  "5e3bc1fc00c8f4c966aa97d3", // tipalti.com
                  "5e3bc09700c8f4c966a56e2f", // tempus.com
                  "5e3bc05d00c8f4c966a4a908", // ramp.com
                  "5e3a8f1a040ca7b0c6f052bd", // swiggy.com
                  "5e3b963c137e998b5aebdde5", // dream11.com
                  "5e3ba11600c8f4c9668bd2f4", // fireblocks.com
                  "5e3ba24700c8f4c9668cffdf", // flexport.com
                  "5e3a8f1c040ca7b0c6f084cc", // falconx.io
                  "5e3a8f4b040ca7b0c6f16337", // starkware.co
                  "636378b0aab093538e030ca1", // carisls.com
                  "606f1e966a1d545278659b4f", // hopin.com
                  "5e3a8f1e040ca7b0c6f0abcb", // dapperlabs.com
                  "5e3a8f1a040ca7b0c6f04972", // netskope.com
                  "5e3a8f19040ca7b0c6f02f3c", // razorpay.com
                  "5e3a8f1d040ca7b0c6f0937c", // automattic.com
                  "5e3bc03400c8f4c966a41d22", // olacabs.com
                  "5e3a8f19040ca7b0c6f0352b", // carta.com
                  "5e3a8f1a040ca7b0c6f05527", // snyk.io
                  "5e3a8fca040ca7b0c6f39d38", // scale.com
                  "5e3a8f1a040ca7b0c6f04c7a", // gong.io
                  "5e3a8f1c040ca7b0c6f08024", // gemini.com
                  "5e3b9097137e998b5ae728c0", // toss.im
                  "5e3bbca100c8f4c96699ee30", // guahao.com
                  "5e3a8f1b040ca7b0c6f06258", // ro.co
                  "5e3b65e8ab6348f63cd9e714", // consensys.net
                  "5e3a8f4b040ca7b0c6f173f4", // automationanywhere.com
                  "5e3a8f19040ca7b0c6f04125", // 1password.com
                  "5e3b65e8ab6348f63cd9e5ae", // klarna.com
                  "5e3bc3ef00c8f4c966b18703", // ziroom.com
                  "5fc7441abf7e53924df6c7c5", // svolt.cn
                  "5e3bbcfe00c8f4c9669b0840", // nseindia.com
                  "6576fb09f5c870dd55a49bb8", // nseindia.com
                  "5e3b65e8ab6348f63cd9e4a5", // mollie.com
                  "5e3a8f1b040ca7b0c6f062e0", // doctolib.fr
                  "5e3a8f19040ca7b0c6f034d4", // cred.club
                  "5e3a8f1a040ca7b0c6f046d7", // datarobot.com
                  "5e3a8f19040ca7b0c6f030fb", // upgrade.com
                  "5e3a8f19040ca7b0c6f041f9", // hingehealth.com
                  "NULL", // blackunicornfactorycalifornia.com
                  "5e3a8f1d040ca7b0c6f0a0e7", // benchling.com
                  "61e696b1f2bbe0f60192dfb9", // global.royole.com
                  "5e3a8f1d040ca7b0c6f0903a", // better.com
                  "5e3a8f4a040ca7b0c6f141b5", // icapitalnetwork.com
                  "62ea2f10542c2517a46862e7", // attentive.com
                  "5e3a8f48040ca7b0c6f1109e", // lyrahealth.com
                  "5e3b924b137e998b5aea0c56", // juran.com.cn
                  "6225f7f1113379b222187a45", // bj.lianjia.com
                  "5e3bc37000c8f4c966afa387", // vice.com
                  "5e3a8f1d040ca7b0c6f09bf4", // cityblock.com
                  "5e3b65e8ab6348f63cd9e6ac", // workato.com
                  "5e3a8f19040ca7b0c6f03498", // backmarket.com
                  "5e3b65e8ab6348f63cd9e3cd", // relexsolutions.com
                  "5e3a8f1b040ca7b0c6f05eca", // boringcompany.com
                  "5f0c07460eb04dccca168d2e", // postman.com
                  "5e3a8f1c040ca7b0c6f08b81", // fivetran.com
                  "5e3a8fa1040ca7b0c6f2c8eb", // contentsquare.com
                  "5e3bc5ed00c8f4c966b38b1d", // airwallex.com
                  "5e3b65e8ab6348f63cd9e459", // mambu.com
                  "5e3a7f250aa7a3270a558e04", // traderepublic.com
                  "63eb90c21e6a68488a047568", // rappi.com.mx
                  "5e3a8f1e040ca7b0c6f0b830", // collibra.com
                  "5e3a8f70040ca7b0c6f1904a", // 6sense.com
                  "5e3a8f19040ca7b0c6f03f49", // 6sense.com
                  "5e3a8f19040ca7b0c6f02e2c", // onetrust.com
                  "5e3bc2e800c8f4c966adcf98", // quintoandar.com.br
                  "5e3b925f137e998b5aea3353", // c6bank.com.br
                  "5e3ba13700c8f4c9668bf257", // miflink.com
                  "63eb90be1e6a68488a042389", // hello-inc.com
                  "647e93a911c3a8c65abcb969", // horizon.auto
                  "6225f7f6113379b2221894de", // howdengroupholdings.com
                  "5efda84670cf9f51dafdce0f", // sambanova.ai
                  "6183aee0ee1af0b5abf05f1d", // zepzpay.com
                  "5e3b65e8ab6348f63cd9eaf3", // oaknorth.com
                  "5e3bbcd100c8f4c9669a6f58", // moonactive.com
                  "5e3bbfde00c8f4c966a31378", // ofbusiness.com
                  "5e3a8f19040ca7b0c6f02f30", // cockroachlabs.com
                  "5e3bc0e300c8f4c966a6943c", // pinelabs.com
                  "6035cf1e4ddab8d8bf476eb8", // qonto.com
                  "61644485c3c073075bd20fbf", // olaelectric.com
                  "5e3ba2fb00c8f4c9668dbbc8", // icertis.com
                  "5e3a8f19040ca7b0c6f03061", // hopper.com
                  "5e3bc35d00c8f4c966af660b", // verse.in
                  "5e3bc08600c8f4c966a53560", // sharechat.com
                  "5ff7c5d81c505c0263f3510d", // coalitioninc.com
                  "5e3a8f19040ca7b0c6f03883", // grafana.com
                  "5e3ba2a400c8f4c9668d6f9b", // meesho.com
                  "620b43aad1fb898462f7c916", // cerebral.com
                  "60226ff24ddab8d8bf9be568", // creditas.com
                  "5e3a8f4a040ca7b0c6f1531d", // reifyhealth.com
                  "61d593c8db415a1b7f2ec9d3", // chiponeic.com
                  "5f76ba83a2734681ce2beb19", // betterup.com
                  "5e3a8f1b040ca7b0c6f0659c", // pleo.io
                  "5e3a7f290aa7a3270a55d2ce", // sonarsource.com
                  "5e3a8f1b040ca7b0c6f06c19", // checkr.com
                  "5e3a8f19040ca7b0c6f03b62", // color.com
                  "5e3a8f19040ca7b0c6f043e5", // lendable.co.uk
                  "5e3bc29800c8f4c966acd766", // ubtrobot.com
                  "5e3b65e8ab6348f63cd9e528", // vinted.com
                  "5e3bc39500c8f4c966b022fb", // vipkid.com.cn
                  "5e3a8f19040ca7b0c6f0319d", // socure.com
                  "5e3a8f1b040ca7b0c6f068ef", // monzo.com
                  "5e3b918a137e998b5ae8cc88", // wefox.com
                  "5e3bbdec00c8f4c9669d9be7", // lenskart.com
                  "61643d92c3c073075bcb8983", // anthropic.com
                  "5e3a8f1e040ca7b0c6f0c1d8", // outreach.io
                  "5e3bbf1600c8f4c966a0ce11", // weride.ai
                  "6475d50111c3a8c65aaae452", // guilde.com
                  "5e3a8f19040ca7b0c6f03318", // arcticwolf.com
                  "6180cca9ee1af0b5ab6ba445", // arcticwolf.com
                  "6022657d4ddab8d8bf9683cc", // arcticwolf.com
                  "5e3bc0c700c8f4c966a62484", // outsystems.com
                  "5e3a7f290aa7a3270a55d3b2", // sorare.com
                  "62ea31f5542c2517a47863eb", // miaoshou.com
                  "5e3a8f1b040ca7b0c6f06727", // flyzipline.com
                  "5e3a8f19040ca7b0c6f030ac", // relativityspace.com
                  "5e3a8f45040ca7b0c6f0d46f", // thoughtspot.com
                  "5e3ba32100c8f4c9668df629", // globalization-partners.com
                  "5e3b8cf5137e998b5ae0d130", // getdbt.com
                  "5e3bc24b00c8f4c966aba8e0", // ssense.com
                  "5e3a8f1a040ca7b0c6f0443d", // bitpanda.com
                  "646aef771735ef7dec74152e", // bitpanda.com
                  "5e3bc5f300c8f4c966b3914e", // dataminr.com
                  "5e3bc37800c8f4c966afc49b", // dataminr.com
                  "5e3a8f1d040ca7b0c6f09f83", // dataminr.com
                  "5e3a8f19040ca7b0c6f032b6", // sayweee.com
                  "5e3bc15400c8f4c966a83b0f", // skydance.com
                  "5e3a8fcb040ca7b0c6f3c957", // skydance.com
                  "5e3bbc0500c8f4c96697e2eb", // houzz.com
                  "5e3bbf6200c8f4c966a1c0e1", // houzz.com
                  "5e3bc3d500c8f4c966b11491", // yellomobile.com
                  "5e3ba37600c8f4c9668e7c29", // megvii.com
                  "5e3bbc3b00c8f4c966987bdc", // greensill.com
                  "5e3ba48100c8f4c96690264c", // impossiblefoods.com
                  "5e3bc30500c8f4c966ae4363", // radpartners.com
                  "5f76ba8ba2734681ce2c25fe", // nextinsurance.com
                  "5e3bc14700c8f4c966a808da", // pointclickcare.com
                  "5e3a8f1a040ca7b0c6f04738", // zapier.com
                  "5edf2f6482c67200733d520a", // joinclubhouse.com
                  "5e3b91cc137e998b5ae9352a", // browserstack.com
                  "6576fbbef5c870dd55a49e40", // oliveai.com
                  "5e3bc8d700c8f4c966b5cd11", // oliveai.com
                  "5e3bc22100c8f4c966ab118c", // rubrik.com
                  "5e3a8f19040ca7b0c6f03311", // meliopayments.com
                  "5e3a8f1a040ca7b0c6f04c40", // vuoriclothing.com
                  "5e3ba2dd00c8f4c9668d9c50", // clickup.com
                  "5e3a8f1c040ca7b0c6f0775e", // cerebras.net
                  "5e3a8f19040ca7b0c6f03517", // fbn.com
                  "65d3a744a88abbdf5b6ce7f3", // branch.io
                  "5e3b65e8ab6348f63cd9e465", // branch.io
                  "5e3a8f70040ca7b0c6f18d66", // aurorasolar.com
                  "5e3a8f1b040ca7b0c6f070be", // webflow.com
                  "624b26e3e4e2dbb8bd1d6b7c", // yuga.com
                  "5e3b9217137e998b5ae9b1be", // godigit.com
                  "5e3a8f1e040ca7b0c6f0b24f", // abnormalsecurity.com
                  "5e3bbf2b00c8f4c966a12505", // hozonauto.com
                  "5e3ba29500c8f4c9668d5878", // medlinker.com
                  "5e3bbc9000c8f4c96699b874", // intarcia.com
                  "5e3a8f19040ca7b0c6f02f24", // stockx.com
                  "5e3b65e8ab6348f63cd9e3d5", // messagebird.com
                  "5e3a8f19040ca7b0c6f02e51", // convoy.com
                  "5e3a8f9f040ca7b0c6f29ff1", // articulate.com
                  "5e3a8f1a040ca7b0c6f04a8d", // dutchie.com
                  "5e3a8f1d040ca7b0c6f0966f", // cohesity.com
                  "5e3a8f19040ca7b0c6f0307e", // dataiku.com
                  "5e3a8f19040ca7b0c6f03376", // vastdata.com
                  "5e3a8f19040ca7b0c6f0347d", // goatgroup.com
                  "5e3b8f72137e998b5ae54161", // noom.com
                  "606f14e36a1d5452784d7fe7", // redwoodmaterials.com
                  "5e3a8f19040ca7b0c6f0327a", // papayaglobal.com
                  "6225f7eb113379b2221859dd", // abogenbio.com
                  "5e3a8f19040ca7b0c6f02ecf", // harness.io
                  "5e3b90b6137e998b5ae762fe", // whatnot.com
                  "61643d9fc3c073075bcb9722", // autograph.io
                  "5e3ba31100c8f4c9668ddb58", // relativity.com
                  "5e3a8f19040ca7b0c6f0388a", // whoop.com
                  "5e3a8fc8040ca7b0c6f36a7f", // appliedintuition.com
                  "5e3b8d8a137e998b5ae20438", // coursehero.com
                  "5e3bc22a00c8f4c966ab27bf", // spoton.com
                  "5e3bc0a900c8f4c966a5b07b", // ottobock.com
                  "5e3bbc4600c8f4c96698adc1", // indigoag.com
                  "5e3bc5c200c8f4c966b354e1", // etoro.com
                  "606f60f06a1d545278f25c41", // hyalroute.com
                  "5f0c07630eb04dccca17533a", // recroom.com
                  "5e3b8d45137e998b5ae190a9", // commure.com
                  "5e3a8f4b040ca7b0c6f16771", // mirakl.com
                  "5e3a8f19040ca7b0c6f02e36", // tekion.com
                  "5e3a7f150aa7a3270a54c292", // celsius.network
                  "5e3a7f170aa7a3270a54d570", // gostudent.org
                  "5e3a8f1b040ca7b0c6f06b59", // highspot.com
                  "5e3a8f73040ca7b0c6f1df2c", // joinhandshake.com
                  "5e3b65e8ab6348f63cd9e58f", // chargebee.com
                  "5e3b64d0ab6348f63cd99240", // flocksafety.com
                  "5e3a8f49040ca7b0c6f12a4b", // helionenergy.com
                  "5e3a8f1a040ca7b0c6f05434", // acronis.com
                  "5e3a8f49040ca7b0c6f13282", // ridewithvia.com
                  "5e3a8f19040ca7b0c6f032fc", // unacademy.com
                  "5e3b65e8ab6348f63cd9e5d3", // odoo.com
                  "5fc74376bf7e53924df218a1", // odoo.com
                  "5ff7c8de1c505c0263fa1ea7", // moonpay.com
                  "5e3bc31700c8f4c966ae7f25", // upstox.com
                  "5e3bbb8200c8f4c96696c3ff", // improbable.io
                  "5e3bc3e000c8f4c966b13d95", // youxiamotors.com
                  "5e3a8f1c040ca7b0c6f07681", // ownbackup.com
                  "60c1e7975e744335aae7bea7", // starburst.io
                  "5e3bc0fb00c8f4c966a6ebca", // silanano.com
                  "5e3a8f19040ca7b0c6f0385b", // komodohealth.com
                  "5e3ba015137e998b5aeea9a2", // kurly.com
                  "5e3b9561137e998b5aeba416", // cars24.com
                  "5e3a8f1b040ca7b0c6f07359", // blockdaemon.com
                  "5e3b9cf0137e998b5aedc477", // dadicinema.com
                  "5e3a7f180aa7a3270a54e061", // aiven.io
                  "5e3a8fcb040ca7b0c6f3bbfe", // asteralabs.com
                  "5e3bc1c300c8f4c966a9c292", // thumbtack.com
                  "5e3a8f1d040ca7b0c6f0998a", // cedar.com
                  "5e3a8f19040ca7b0c6f03d01", // eruditus.com
                  "5e3a8fc5040ca7b0c6f32de5", // blockstream.com
                  "5e3a8f1d040ca7b0c6f09bf6", // innovaccer.com
                  "5e3a8f19040ca7b0c6f03ed6", // ironcladapp.com
                  "6041f83b4ddab8d8bf1897bb", // eikontx.com
                  "5e3bc62100c8f4c966b3b98f", // retool.com
                  "5e3a8f19040ca7b0c6f03209", // verkada.com
                  "5e3a8f19040ca7b0c6f03c2c", // applyboard.com
                  "5e3b8f96137e998b5ae585bd", // psiquantum.com
                  "5e3a8f19040ca7b0c6f032fe", // udaan.com
                  "5e3bbe2300c8f4c9669def85", // highradius.com
                  "5e3bc5b500c8f4c966b33b02", // nuvemshop.com.br
                  "615ade9bfb22921ca32e000d", // layerzero.network
                  "5e3bc3ec00c8f4c966b167ba", // yixia.com
                  "5e3bc21300c8f4c966aacf19", // traveloka.com
                  "5e3bc1f800c8f4c966aa7ffc", // souche.com
                  "5e3b92c3137e998b5aead186", // bglgroup.co.uk
                  "5e3ba14d00c8f4c9668bfe55", // circle.com
                  "6576fa7bf5c870dd55a499c5", // circle.com
                  "5e3bc40900c8f4c966b1c29a", // zybang.com
                  "5e3bc98600c8f4c966b66a12", // flixbus.com
                  "5e3b91a1137e998b5ae8f614", // wildlifestudios.com
                  "5e3a8f48040ca7b0c6f10e55", // forter.com
                  "5e3a8f45040ca7b0c6f0ccef", // calendly.com
                  "5e3a8f19040ca7b0c6f02e37", // blockfi.com
                  "5e3a8f1b040ca7b0c6f068db", // activecampaign.com
                  "5e3a8f1d040ca7b0c6f0a5b6", // cmrsurgical.com
                  "606ef71a6a1d545278105a70", // lucid.co
                  "5e3a8f1b040ca7b0c6f06d09", // ageoflearning.com
                  "5e3b9b48137e998b5aed4a74", // carbonhealth.com
                  "5e3a8f1d040ca7b0c6f0a325", // contentful.com
                  "5e3bbcbf00c8f4c9669a375e", // launchdarkly.com
                  "5e3a8f1c040ca7b0c6f07ca8", // seismic.com
                  "5e48fce8cee276666c345d84", // skymavis.com
                  "5e3a8f1c040ca7b0c6f0898a", // outschool.com
                  "5e3a8f1a040ca7b0c6f05b56", // tradingview.com
                  "5e3bc98800c8f4c966b67251", // groww.in
                  "5e3a8f19040ca7b0c6f03ff1", // podium.com
                  "5e3a8f1c040ca7b0c6f088f5", // anchorage.com
                  "5e3a8f1c040ca7b0c6f08abc", // inxeption.com
                  "5e3a8f19040ca7b0c6f02e8a", // lattice.com
                  "5e3a8f19040ca7b0c6f02ed1", // flutterwave.com
                  "5e3bc83400c8f4c966b5553d", // ambergroup.io
                  "60e6ac8e7d096fb914e00e15", // crossriver.com
                  "5e3a8f1d040ca7b0c6f09b05", // remote.com
                  "5e3a8f46040ca7b0c6f0e616", // sentry.io
                  "5e3ba49800c8f4c966905573", // kkguan.com
                  "5e3bbbf500c8f4c96697a8fc", // kraken.com
                  "604264524ddab8d8bf47f39a", // workrise.com
                  "5e3bbf2100c8f4c966a0ef52", // loft.com.br
                  "NULL", // gokinsolar.com
                  "5e3a8f19040ca7b0c6f02e48", // bharatpe.com
                  "5e3a8f19040ca7b0c6f042f6", // drivewealth.com
                  "61420f53269e103bdb323417", // alan.com
                  "602262de4ddab8d8bf9538af", // gomotive.com
                  "5e3ba2d300c8f4c9668d8e74", // meicai.cn
                  "5e3bbf7200c8f4c966a20ad1", // nutrabolt.com
                  "5e3a8f1b040ca7b0c6f05cb0", // graphcore.ai
                  "5e3a8f19040ca7b0c6f0429f", // illumio.com
                  "5e3a8f19040ca7b0c6f035d1", // masterclass.com
                  "5e3b8d3a137e998b5ae16749", // dreamgames.com
                  "5e3a8f19040ca7b0c6f034fd", // transmitsecurity.com
                  "5e3a8f1a040ca7b0c6f049f6", // tradeshift.com
                  "5e3bbe3500c8f4c9669e2cea", // nextiva.com
                  "5e3b9135137e998b5ae83dca", // tridge.com
                  "5e3bbfb200c8f4c966a2acb9", // o9solutions.com
                  "5e3a7f150aa7a3270a54c8ff", // thoughtmachine.net
                  "5e3a8f1d040ca7b0c6f0942b", // ethoslife.com
                  "5e3a8f1c040ca7b0c6f078b1", // starlingbank.com
                  "5e3bc3e300c8f4c966b1532f", // zetwerk.com
                  "5e3b912d137e998b5ae83148", // backbase.com
                  "61e69649f2bbe0f601926319", // about.sourcegraph.com
                  "5e3a8f45040ca7b0c6f0cf68", // jumpcloud.com
                  "5e3a8f1a040ca7b0c6f05c6e", // project44.com
                  "5e3a8f1d040ca7b0c6f09bcb", // freenome.com
                  "5e3a8f77040ca7b0c6f245c5", // manomano.fr
                  "5e3a8f47040ca7b0c6f10389", // pendo.io
                  "5faa8b65c1c90b522765aab8", // plume.com
                  "5e3a8f1a040ca7b0c6f0512f", // moglix.com
                  "5e3a8f1a040ca7b0c6f04774", // axonius.com
                  "613f26c6f5988c252eb2f375", // unico.io
                  "5e3a8f1b040ca7b0c6f061cd", // clari.com
                  "606f19376a1d54527855f1df", // ju.st
                  "5e3a7f150aa7a3270a54c804", // ouraring.com
                  "5e3a8f75040ca7b0c6f20f81", // cribl.io
                  "5e3a8fa2040ca7b0c6f2e9b1", // jettiresources.com
                  "5e3bc39700c8f4c966b0352f", // vistajet.com
                  "5e3b926d137e998b5aea47e6", // byton.com
                  "5e3b65e8ab6348f63cd9eade", // side.com
                  "5e3a8f1a040ca7b0c6f0448c", // springhealth.com
                  "5e3bbc4a00c8f4c96698c473", // infra.market
                  "5e3a8f19040ca7b0c6f02e14", // varomoney.com
                  "5e3b9695137e998b5aebf4e7", // catonetworks.com
                  "5e3a8fa0040ca7b0c6f2a88a", // aura.com
                  "5ef2c5940eb04dccca6ee869", // vercel.com
                  "5e3a8f19040ca7b0c6f03e60", // sysdig.com
                  "5e3bc2ce00c8f4c966ad6545", // uniphore.com
                  "5e3bc1a500c8f4c966a9602e", // somatus.com
                  "5e3bbc0500c8f4c96697e69a", // immutable.com
                  "5e3a8f1a040ca7b0c6f05649", // a24films.com
                  "5e3a8f1b040ca7b0c6f0632c", // sifive.com
                  "606ef4e46a1d5452780cbcb5", // games24x7.com
                  "5e3a8f1c040ca7b0c6f08e5c", // fetch.com
                  "6576faa1f5c870dd55a49a50", // fetch.com
                  "5e3b9149137e998b5ae8662f", // codapayments.com
                  "5e3a8f19040ca7b0c6f03fc7", // drivenets.com
                  "5e3a8f19040ca7b0c6f03109", // hibob.com
                  "5e3bc29a00c8f4c966ace91b", // uala.com.ar
                  "5e3bbc5500c8f4c96698e726", // mpl.live
                  "5e3b9b32137e998b5aed445f", // cgtz.com
                  "61081020ba073244e198528b", // starcharge.com
                  "5e3a8f19040ca7b0c6f02e1b", // equipmentshare.com
                  "5e3a8f74040ca7b0c6f1f754", // carbon3d.com
                  "5e3bc1b200c8f4c966a9914c", // rokt.com
                  "5e3b65e8ab6348f63cd9e484", // policybazaar.com
                  "5e3b93e8137e998b5aeb3f34", // policybazaar.com
                  "5e3a8f1b040ca7b0c6f06828", // paxos.com
                  "5e3a8f1c040ca7b0c6f08252", // exabeam.com
                  "5e3bc11600c8f4c966a7422c", // exabeam.com
                  "5e3b96b3137e998b5aebfcc0", // bitsight.com
                  "5e3a8f1a040ca7b0c6f04cbf", // trumid.com
                  "5e3a8f45040ca7b0c6f0cfef", // ablspacesystems.com
                  "5e3bbed800c8f4c966a02c37", // honeybook.com
                  "5e3b929e137e998b5aea94b0", // beta.team
                  "624b265ee4e2dbb8bd10235a", // manypets.com
                  "5e3b65e8ab6348f63cd9e63f", // jobandtalent.com
                  "62ea2ddc542c2517a46165c5", // front.wemakeprice.com
                  "5eb38522dd18f7e0684ad24b", // birentech.com
                  "5e3a8f1e040ca7b0c6f0bc65", // voodoo.io
                  "5e3a8f74040ca7b0c6f1f599", // shield.ai
                  "5e3bc30e00c8f4c966ae5329", // uptake.com
                  "5e3bbbd000c8f4c9669742db", // greenlight.com
                  "5e3a8f1e040ca7b0c6f0b49b", // boweryfarming.com
                  "5e3bbc7000c8f4c9669945d6", // momo.vn
                  "5e3a8f48040ca7b0c6f11c76", // algolia.com
                  "5e3bbfef00c8f4c966a34aa2", // pattern.com
                  "5e3a8f1a040ca7b0c6f0466d", // upgrad.com
                  "5f85491f4b03336c9c93f226", // coreweave.com
                  "5f35f74871eeac40b6323459", // newfront.com
                  "5e3bbf6100c8f4c966a1bd25", // nucom.group
                  "5e3a8f1a040ca7b0c6f0519d", // skydio.com
                  "64bae500507037b64d88e4fc", // global.musinsa.com
                  "5e3a8f1b040ca7b0c6f07498", // current.com
                  "5e3b9676137e998b5aebed21", // bitso.com
                  "5e3bbc7400c8f4c96699538d", // gympass.com
                  "5e3a8f49040ca7b0c6f12d12", // dialpad.com
                  "5e48fce7cee276666c345a7d", // g7.com.cn
                  "5e3b9905137e998b5aeca19d", // bloomreach.com
                  "5e3a8f4c040ca7b0c6f17750", // madewithmotif.com
                  "5e3bbf0100c8f4c966a09473", // huaqin.com
                  "606f71486a1d54527801b59b", // accelins.com
                  "5e3bc3c200c8f4c966b0d565", // yitutech.com
                  "5e48fce7cee276666c345a9b", // addepar.com
                  "5e3bc2d900c8f4c966ad8821", // qualia.com
                  "6225f7ee113379b2221867fc", // cloudwalk.com
                  "5e3b9157137e998b5ae8732d", // coindcx.com
                  "5e3ba16400c8f4c9668c130a", // firstcry.com
                  "5e3a8f19040ca7b0c6f030b2", // eightfold.ai
                  "606f0f5b6a1d5452783d049e", // urbancompany.com
                  "5e3a8f1c040ca7b0c6f084e3", // rechargepayments.com
                  "5e3a8f1b040ca7b0c6f0727d", // moveworks.com
                  "5e3a8f1a040ca7b0c6f04bde", // medable.com
                  "5e3b65e8ab6348f63cd9e82c", // gocardless.com
                  "5e3a8f1a040ca7b0c6f059c7", // aledade.com
                  "5faa8b52c1c90b5227659b6d", // forto.com
                  "5faa8b78c1c90b522765b925", // tryjeeves.com
                  "5e3a8f19040ca7b0c6f0304b", // payfit.com
                  "5e3b65e8ab6348f63cd9e687", // vivawallet.com
                  "5e3bc3ca00c8f4c966b0f3ef", // xinchao.com
                  "5e3b65e8ab6348f63cd9e5ba", // neo4j.com
                  "5fc742babf7e53924ded26a4", // babel.finance
                  "60bf9d2e5e744335aa6a6a66", // bezerocarbon.com
                  "NULL", // trendygp.com
                  "5e3a8f19040ca7b0c6f03966", // grubmarket.com
                  "64940fff11c3a8c65ac510ff", // cohere.com
                  "5e3a8f1a040ca7b0c6f058fe", // go1.com
                  "5f0c07210eb04dccca15963d", // clearstreet.io
                  "601c0f6b4ddab8d8bfeb59f0", // drata.com
                  "61644f4ec3c073075bdf9757", // mystenlabs.com
                  "5e3a8fa0040ca7b0c6f2b4a4", // avant.com
                  "NULL", // tubatu.com
                  "5e3b8cf0137e998b5ae0c3f0", // blablacar.com
                  "5e3bbf1300c8f4c966a0c13d", // huimin.cn
                  "5e3a8f1c040ca7b0c6f07744", // quora.com
                  "5e3bc1c500c8f4c966a9d11a", // preferred.jp
                  "5e3a8f1d040ca7b0c6f08f1e", // formlabs.com
                  "5e3a8f19040ca7b0c6f03c8e", // 4paradigm.com
                  "5e3a8f1a040ca7b0c6f0521f", // calm.com
                  "5e3bc05800c8f4c966a48084", // kaseya.com
                  "5e3bbfb500c8f4c966a2b7d8", // mafengwo.cn
                  "5e3b9756137e998b5aec2b77", // druva.com
                  "5e3a8f72040ca7b0c6f1c158", // kujiale.com
                  "5e3a8f19040ca7b0c6f04244", // appsflyer.com
                  "643cc444a7f08fcce04783ac", // keephq.dev
                  "6141fefb269e103bdb207657", // redis.com
                  "5f851b204b03336c9c7b8779", // xingyungroup.com
                  "5e3a8f19040ca7b0c6f03a59", // unqork.com
                  "5e3a8f1c040ca7b0c6f08e73", // virtahealth.com
                  "5e3bbe3f00c8f4c9669e46df", // isnetworld.com
                  "5e3b9447137e998b5aeb5900", // earnix.com
                  "5e3a8fcb040ca7b0c6f3c947", // zzgqsh.com
                  "60c211d95e744335aaf4251d", // clear.co
                  "5e3a8f19040ca7b0c6f039ab", // hive.com
                  "5e3b65e8ab6348f63cd9e3d7", // kry.se
                  "5e3a8f1a040ca7b0c6f045f0", // kajabi.com
                  "5e3b9234137e998b5ae9e0da", // beisen.com
                  "5e3bc12700c8f4c966a78961", // pipe.com
                  "5e3a8f19040ca7b0c6f037f2", // iterable.com
                  "5e3a8f1d040ca7b0c6f09394", // bunq.com
                  "5e3bc00000c8f4c966a368cd", // clip.mx
                  "5e3bbeef00c8f4c966a075a3", // loggi.com
                  "5e3bc5bf00c8f4c966b34cf9", // mural.co
                  "5e3bbfa900c8f4c966a2922e", // ocsial.com
                  "5e3a8f1c040ca7b0c6f08c7d", // xtalpi.com
                  "5e3a8f1a040ca7b0c6f04fee", // divvyhomes.com
                  "5e3a8f1b040ca7b0c6f06bd6", // apeel.com
                  "616444bcc3c073075bd24237", // opayweb.com
                  "5e3a8f19040ca7b0c6f03520", // aviatrix.com
                  "5e3bc17200c8f4c966a895c6", // smartnews.com
                  "5e3a8f19040ca7b0c6f032d5", // misfitsmarket.com
                  "5e3a8f4b040ca7b0c6f1725a", // bst.ai
                  "638dbdc7a1d483d56344801e", // advancegroup.com
                  "5e3a8f1a040ca7b0c6f05cae", // moderntreasury.com
                  "5e3bbfb900c8f4c966a2d245", // magicleap.com
                  "5e3a8f19040ca7b0c6f04082", // tier.app
                  "615af1a5fb22921ca33d4cdd", // clickhouse.com
                  "5e3a8f1d040ca7b0c6f09413", // everlaw.com
                  "633ac7545732d6a1e43a069f", // payzilch.com
                  "5e3a8f1d040ca7b0c6f09a26", // verbit.ai
                  "606efdfa6a1d545278227a6f", // netlify.com
                  "5e3a8f46040ca7b0c6f0e3ae", // swordhealth.com
                  "62c2c5300405ea8a58bcec74", // shopltk.com
                  "5e3a8f1a040ca7b0c6f045ce", // traxretail.com
                  "5e955ba852238f094e76a8f3", // exotec.com
                  "5e3a8f45040ca7b0c6f0d712", // dremio.com
                  "5e3a8f1a040ca7b0c6f05938", // akulaku.com
                  "5e3bc5bf00c8f4c966b34a42", // cloudinary.com
                  "5eb38524dd18f7e0684ae510", // lunar.app
                  "5e3a8f1d040ca7b0c6f0a9aa", // salsify.com
                  "621f951c972ef7e5d69552fa", // certik.com
                  "629cc103cf28682e15f091e8", // roxmotor.com
                  "5e3bbf5300c8f4c966a18ce9", // huggingface.co
                  "5e3a8f45040ca7b0c6f0ced6", // devo.com
                  "606f9b206a1d54527811e18f", // shiftkey.com
                  "6270fe884b7ad6a07cbc5fb8", // rimac-group.com
                  "6225f7f3113379b2221880bf", // geekplus.com
                  "636378ffaab093538e064458", // 21.co
                  "624e9218e55c24d5a44283f0", // solugen.com
                  "5e3b9f2e137e998b5aee692b", // chippercash.com
                  "5e3cca4a00c8f4c966c1e96c", // ankorstore.com
                  "5e3a8f19040ca7b0c6f031a9", // feverup.com
                  "5e3a8f19040ca7b0c6f03be7", // roofstock.com
                  "5e3a8f71040ca7b0c6f1ac37", // thatgamecompany.com
                  "5e3bc3e200c8f4c966b14d5a", // zigbang.com
                  "5e3bbd5e00c8f4c9669bf276", // invisionapp.com
                  "5e3b9548137e998b5aeb9d6a", // edaili.com
                  "5e3bbc9c00c8f4c96699dd3b", // mx.com
                  "5e3b9180137e998b5ae8b083", // coinswitch.co
                  "5e3a8f19040ca7b0c6f03201", // acorns.com
                  "602274e24ddab8d8bf9e7f72", // concertai.com
                  "5e3a8f19040ca7b0c6f040d9", // materialbank.com
                  "5e3a7f150aa7a3270a54c793", // volocopter.com
                  "5e3ba0ff00c8f4c9668bc259", // enovatemotors.com
                  "5e3b9b5b137e998b5aed4f98", // cfgi.com
                  "5e3ba43e00c8f4c9668fa736", // megazone.com
                  "5e3bc3eb00c8f4c966b163e2", // zocdoc.com
                  "5e3bc30300c8f4c966ae3217", // scroll.com
                  "5e3a8f1b040ca7b0c6f06a20", // diamondfoundry.com
                  "5e3a8f1b040ca7b0c6f073cc", // quantexa.com
                  "5e3a8f19040ca7b0c6f03342", // fullstory.com
                  "5e3a8f1b040ca7b0c6f069b5", // lightricks.com
                  "5e3a8f1a040ca7b0c6f04e12", // opentrons.com
                  "5e3a8f1b040ca7b0c6f06867", // kindbody.com
                  "5e3bc14700c8f4c966a80752", // sliceit.com
                  "5e3a8f1e040ca7b0c6f0ab01", // alpha-sense.com
                  "5e3a8f72040ca7b0c6f1bba7", // orca.security
                  "5e68a6c22bcca6b0a57f2fa6", // cambrianbio.com
                  "5e3a8f19040ca7b0c6f0372f", // ai-ways.com
                  "5e3b64d0ab6348f63cd992b8", // trulioo.com
                  "5e3bc24100c8f4c966ab92f9", // spinny.com
                  "5e3b9f55137e998b5aee73cc", // crusoeenergy.com
                  "5e3a8f1c040ca7b0c6f07988", // apusapps.com
                  "5e3a8f1b040ca7b0c6f06f1c", // productboard.com
                  "5e3a8f19040ca7b0c6f03e76", // forte.io
                  "5e3bbd1e00c8f4c9669b5c4b", // harrys.com
                  "5f758f7ea2734681ce1ca75f", // pax.com
                  "5e3a8f1c040ca7b0c6f084b3", // dispatchhealth.com
                  "5e3a8f1a040ca7b0c6f051b9", // pax8.com
                  "606f1b836a1d5452785cf836", // multiverse.io
                  "616440ebc3c073075bceb5f1", // front.com
                  "5e3bc09300c8f4c966a5600d", // redesignhealth.com
                  "5e3a8f1d040ca7b0c6f09ee0", // circleci.com
                  "5eb38527dd18f7e0684afcaf", // wave.com
                  "5e3b65e8ab6348f63cd9e59a", // vestiairecollective.com
                  "5e3b924c137e998b5aea0fdb", // dragos.com
                  "5e3a8f72040ca7b0c6f1bb75", // reltio.com
                  "5e3b65e8ab6348f63cd9e617", // h2o.ai
                  "5e3a8f48040ca7b0c6f11ac7", // zenbusiness.com
                  "5e3b9556137e998b5aeba13f", // carsome.my
                  "5e3a7f290aa7a3270a55dac3", // spotter.com
                  "5e3b8cf3137e998b5ae0c951", // dealshare.in
                  "5e3a8f1a040ca7b0c6f04a4b", // homelight.com
                  "5e3a8f1e040ca7b0c6f0bc02", // gousto.co.uk
                  "6180da93ee1af0b5ab7341cc", // alation.com
                  "5e3a8f48040ca7b0c6f11e0f", // alation.com
                  "602268284ddab8d8bf97d506", // tesseratherapeutics.com
                  "5f5f3652a2734681cecc829e", // uniswap.org
                  "651d2c9848a2fc3af4509830", // kredivocorp.com
                  "5f5f3657a2734681cecc8dcd", // optimism.io
                  "5e3bc8b400c8f4c966b5b6d2", // incrediblehealth.com
                  "5f76ba8ba2734681ce2c2b14", // solarisbank.com
                  "5e3a8f1b040ca7b0c6f0705e", // glossier.com
                  "5e3bbf9200c8f4c966a251a4", // jusfoun.com
                  "61e6af6df2bbe0f601ceb282", // transcarent.com
                  "5e3bc3fc00c8f4c966b19da1", // zbj.com
                  "5e3a8f19040ca7b0c6f03d27", // uniteus.com
                  "5e3b9f2c137e998b5aee689b", // datastax.com
                  "5e3bbc0400c8f4c96697dbe2", // infinidat.com
                  "5e3a8f1e040ca7b0c6f0ae7e", // afiniti.com
                  "5e3b99bc137e998b5aecd46e", // caocaokeji.cn
                  "5e3b8d61137e998b5ae1bf64", // clio.com
                  "5e3a8f46040ca7b0c6f0dc14", // safetyculture.com.au
                  "5f0c06b90eb04dccca12d43d", // montecarlodata.com
                  "5f8548e24b03336c9c924d0f", // cresta.com
                  "5e3a8f77040ca7b0c6f237c3", // asapp.com
                  "5e3bbc1d00c8f4c966981193", // smarthr.co.jp
                  "5f76ba8ba2734681ce2c2b1b", // mercury.com
                  "5e3a8f1a040ca7b0c6f04ee0", // truepill.com
                  "5e3b8e07137e998b5ae2d782", // ezcater.com
                  "5e955bc552238f094e77e159", // wayflyer.com
                  "5e3a8f1c040ca7b0c6f07854", // capitolis.com
                  "60f052857d096fb914ba212d", // nordsecurity.com
                  "5e3bc33500c8f4c966aef1f0", // vanta.com
                  "61644f4dc3c073075bdf944f", // magiceden.io
                  "5e3a8fcb040ca7b0c6f3d3f4", // pave.com
                  "5e3a8f49040ca7b0c6f12f55", // astranis.com
                  "5e3bc24d00c8f4c966abb261", // promasidor.com
                  "NULL", // yinhehangtian.cn
                  "5e3a8f1e040ca7b0c6f0addb", // icon.foundation
                  "6022608f4ddab8d8bf940b03", // fresh-scm.cn
                  "5e3a8f4b040ca7b0c6f167ca", // alloy.co
                  "62c2c5430405ea8a58bd5182", // baiwang.com
                  "624b269ee4e2dbb8bd1689a2", // iluvatar.com.cn
                  "5e68d51b2bcca6b0a58c82ae", // wenheyou.com
                  "5e3a8f19040ca7b0c6f038e0", // kitopi.com
                  "5e3a8f19040ca7b0c6f04062", // loom.com
                  "5e3bc3ca00c8f4c966b0f42c", // ximalaya.com
                  "606f15466a1d5452784e4d11", // ncarzone.com
                  "5e3a8f1b040ca7b0c6f0699e", // cognite.com
                  "5e3a8f1a040ca7b0c6f04e40", // runwayml.com
                  "5faa8b69c1c90b522765ada1", // openweb.com
                  "60e6aa5c7d096fb914df7378", // bolttech.io
                  "5e3b8d6f137e998b5ae1d499", // firstmode.com
                  "61319bd9991d47f5fef20ee6", // biltrewards.com
                  "6576fa3af5c870dd55a498eb", // mu-sigma.com
                  "5e3bbd6a00c8f4c9669c252f", // mu-sigma.com
                  "6225f7f9113379b22218a4f6", // kushkipagos.com
                  "5e3a8fa3040ca7b0c6f30654", // jasper.ai
                  "60425fe84ddab8d8bf468820", // ornatx.com
                  "5e3bc26e00c8f4c966ac2e2d", // tujia.com
                  "5e3bbc9400c8f4c96699d579", // 52mf.com.cn
                  "5e3bbc9e00c8f4c96699e6e9", // gett.com
                  "5e3b9296137e998b5aea81eb", // dtdream.com
                  "5e3bc3cb00c8f4c966b0fb10", // xiaozhu.com
                  "5e3bc3ce00c8f4c966b10cbd", // yijiupi.com
                  "5e3b92b5137e998b5aeac19c", // cmtelematics.com
                  "5e3a8f1d040ca7b0c6f0a083", // collectivehealth.com
                  "5e3a8f1b040ca7b0c6f06aa8", // strava.com
                  "5e3b90ca137e998b5ae78023", // zenoti.com
                  "60f052767d096fb914ba1495", // khealth.com
                  "5e3bc32200c8f4c966aea406", // uplight.com
                  "5e3ba3ae00c8f4c9668ed615", // id.me
                  "5e3a8f72040ca7b0c6f1c2ec", // snapdocs.com
                  "5e3bbea100c8f4c9669f5574", // nextsilicon.com
                  "5e3bc3e100c8f4c966b14180", // yunquna.com
                  "5e3ba46900c8f4c9668fee37", // impact.com
                  "5e3a8f1e040ca7b0c6f0c358", // smartrecruiters.com
                  "NULL", // thenotcompany.com
                  "5e3a8f1c040ca7b0c6f0871d", // cultureamp.com
                  "5e3b9012137e998b5ae65b4d", // taxbit.com
                  "5fae6235b4cdd835549ffa62", // pacaso.com
                  "615ad7cffb22921ca32878c6", // splitgate.com
                  "5e3a7f2a0aa7a3270a55ea21", // matillion.com
                  "5e3b8fb1137e998b5ae5af3c", // withpersona.com
                  "5e3a8fc5040ca7b0c6f329f6", // ascendcorp.com
                  "5e3a8f49040ca7b0c6f13bee", // andela.com
                  "61acf60ed0efcf20c6d22f65", // built.com
                  "5e3a8f1b040ca7b0c6f05d3f", // coinlist.co
                  "5faa8b58c1c90b522765a055", // lusha.com
                  "5e3a8f19040ca7b0c6f03102", // cure.fit
                  "5e3bc03100c8f4c966a406b2", // olist.com
                  "5e3a8f19040ca7b0c6f034d0", // paradox.ai
                  "624e9226e55c24d5a4429e4c", // airbyte.io
                  "5e3a8f1a040ca7b0c6f04885", // store-dot.com
                  "5e3a7f1b0aa7a3270a55099d", // oddity.ai
                  "5e3a8f19040ca7b0c6f03644", // spendesk.com
                  "5e3bc35e00c8f4c966af72ec", // veriff.com
                  "5e3a8f1e040ca7b0c6f0b9c9", // athelas.com
                  "5e3ba03900c8f4c9668ad68c", // elastic.run
                  "5e3a8f9d040ca7b0c6f26508", // veho-technologies.com
                  "5fae6247b4cdd83554a05037", // temporal.io
                  "5e3a8f19040ca7b0c6f03b9f", // meetfabric.com
                  "608fd82adb60992662b401ae", // zeta.tech
                  "6130ddf6991d47f5fe4fce65", // boba.com
                  "NULL", // upside.app
                  "5e3a8fcb040ca7b0c6f3bb1d", // mashgin.com
                  "61644001c3c073075bcde334", // dock.tech
                  "5e955bc552238f094e77e5d8", // prometheus.io
                  "6130cfb6991d47f5fe405ea2", // 5ire.org
                  "60902074db60992662c5ebc2", // ziphq.com
                  "61644395c3c073075bd13bea", // moloco.com
                  "5e3ba4c000c8f4c96690b535", // inari.com
                  "606f1bc96a1d5452785dcefe", // ypshengxian.com
                  "5e3b90d9137e998b5ae797ff", // bordrin.com
                  "5e3bc91900c8f4c966b6053e", // arcadia.com
                  "5e3b65e8ab6348f63cd9e4ec", // ledger.com
                  "5e3bc34f00c8f4c966af3ab8", // vts.com
                  "5e3bc09200c8f4c966a554c3", // shukun.net
                  "63e07e661e6a68488a7e9f6a", // huasunsolar.com
                  "5e3b92b5137e998b5aeabdec", // coocaa.com
                  "5e3bbfd000c8f4c966a2f65c", // jumapeisong.com
                  "62ea2ea9542c2517a465ed1e", // ouyeel.com
                  "5e3a8f1a040ca7b0c6f05359", // gymshark.com
                  "62fc9954c2beea1fe71900e5", // m1.com
                  "604266ad4ddab8d8bf48b404", // aleo.org
                  "5e3a7f160aa7a3270a54ccee", // einride.tech
                  "5e3a8f1b040ca7b0c6f06c5c", // justworks.com
                  "61acc995d0efcf20c6c0274c", // valgenmed.com
                  "5e3a8f1b040ca7b0c6f06325", // sondermind.com
                  "5e3a8f1b040ca7b0c6f06f61", // amagi.com
                  "5e3bc98800c8f4c966b67282", // joinpapa.com
                  "5e3bc3c100c8f4c966b0d24d", // xpressbees.com
                  "5fd26115165cb213920af8ee", // figment.io
                  "5e3a8f1c040ca7b0c6f0787a", // envoy.com
                  "5e3a7f170aa7a3270a54d10b", // deliverect.com
                  "5e3a8f4a040ca7b0c6f1580c", // sparkcognition.com -> avathon.com
                  "60901036db60992662c38c4f", // liquidity.com
                  "5e3b65e8ab6348f63cd9ef34", // firebolt.io
                  "5e3bbc2700c8f4c966983a24", // koudai.com
                  "5e3bbf2b00c8f4c966a12233", // symphony.com
                  "5e3bc3cd00c8f4c966b10166", // yidianzixun.com
                  "5e3b65e8ab6348f63cd9e4a4", // cabify.com
                  "NULL", // hivebox.com
                  "5e3a8f19040ca7b0c6f03747", // awaytravel.com
                  "5e3a8f1a040ca7b0c6f05025", // konghq.com
                  "5e3a7f200aa7a3270a55504d", // epidemicsound.com
                  "5e3b65e8ab6348f63cd9e4b8", // yotpo.com
                  "5e3a8f1d040ca7b0c6f097e8", // coda.io
                  "5f76ba8ca2734681ce2c2d34", // stash.com
                  "5e3bc2f800c8f4c966ae035f", // radpowerbikes.com
                  "5e3a8f1a040ca7b0c6f05bfe", // clarifyhealth.com
                  "606efd406a1d5452782041d3", // bucketplace.com -> ohou.se
                  "5e3b65e8ab6348f63cd9e3a4", // paddle.com
                  "NULL", // onecard.io
                  "5e3ba03f00c8f4c9668adf45", // boat-lifestyle.com
                  "5f76ba8aa2734681ce2c248c", // phenom.com
                  "5e3bbc9a00c8f4c96699da90", // gupshup.io
                  "5e3ba25800c8f4c9668d138c", // degreed.com
                  "5e3b65e8ab6348f63cd9ece2", // de.scalable.capital
                  "5e3bc37700c8f4c966afb8d1", // videoamp.com
                  "NULL", // panther-labs.com
                  "5e3a8f1d040ca7b0c6f09054", // salt.security
                  "5e3a7f160aa7a3270a54cd6c", // tripledotstudios.com
                  "5e3a8f19040ca7b0c6f02f81", // nimblerx.com
                  "5e3bc22000c8f4c966ab081e", // pristyncare.com
                  "5e955bbd52238f094e778934", // vente-privee.com
                  "NULL", // banconeon.com
                  "6576fad0f5c870dd55a49af3", // extend.com
                  "5e3b979a137e998b5aec3d37", // extend.com
                  "604259334ddab8d8bf4436a5", // generatebiomedicines.com
                  "5e3bbcc000c8f4c9669a3f82", // movableink.com
                  "5e3bc01f00c8f4c966a3ba6e", // patreon.com
                  "5e3ba24c00c8f4c9668d078b", // deepblueai.com
                  "5e3bbbbd00c8f4c966971c73", // klook.com
                  "5e3bc3e400c8f4c966b15ec9", // ysbang.cn
                  "61e697a8f2bbe0f60193f294", // epirus.com
                  "5e3bc5c100c8f4c966b35104", // signifyd.com
                  "62d145d961d5a9a48bfb09c4", // jaguarmicro.com
                  "5e3a7f170aa7a3270a54d0d9", // motorway.co.uk
                  "5e3bbca000c8f4c96699e910", // motorway.co.uk
                  "NULL", // ridi.kr
                  "5e3ba18b00c8f4c9668c378a", // flipboard.com
                  "NULL", // gpclub.vn
                  "5e3bc19300c8f4c966a92139", // tongdun.cn
                  "64bae4fe507037b64d88a7f0", // athleticgreens.com -> drinkag1.com
                  "5e3bc30400c8f4c966ae3a8a", // unisound.com
                  "5e3a8f45040ca7b0c6f0d421", // alzheon.com
                  "5e3ba0ce00c8f4c9668b8f5e", // mastercontrol.com
                  "5e3bc7fc00c8f4c966b52bd4", // heartflow.com
                  "5e3bc98800c8f4c966b67301", // shiprocket.in
                  "620b4395d1fb898462f77d06", // thebrandtechgroup.com
                  "66cdf9f287d89e71efa3b128", // insig.com.ai
                  "615ad760fb22921ca3281f70", // everlyhealth.com
                  "60226d474ddab8d8bf9a7883", // wearemanner.com
                  "5e3bbff000c8f4c966a34e43", // talkingdata.com
                  "5e3a8f19040ca7b0c6f03108", // distrokid.com
                  "5e3bbc2200c8f4c966982980", // konfio.mx
                  "5e3a8f19040ca7b0c6f04112", // betterment.com
                  "5e3b8e1d137e998b5ae2fcab", // flockfreight.com
                  "5e3a8f1b040ca7b0c6f067a4", // yugabyte.com
                  "5e3bc63200c8f4c966b3c813", // lukka.tech
                  "5e3a8f1c040ca7b0c6f08bcc", // travelperk.com
                  "5e3a8f19040ca7b0c6f0356c", // itrustcapital.com
                  "5e3a8f1f040ca7b0c6f0c7b0", // domestika.org
                  "5e3a8f19040ca7b0c6f04039", // cointracker.io
                  "5e3a8f1c040ca7b0c6f07ed5", // loadsmart.com
                  "5e3bc29600c8f4c966acbd29", // tungee.com
                  "NULL", // yubi.io
                  "5e3a8f19040ca7b0c6f038e9", // linktr.ee
                  "6576fbfef5c870dd55a49f25", // linktr.ee
                  "6576fa19f5c870dd55a49877", // linktr.ee
                  "6576fbb7f5c870dd55a49e26", // linktr.ee
                  "6576fd21f5c870dd55a4a339", // linktr.ee
                  "6576faa2f5c870dd55a49a53", // linktr.ee
                  "6576fa04f5c870dd55a4982f", // linktr.ee
                  "60efeba77d096fb91466bae6", // island.io
                  "6270fed64b7ad6a07cbce66a", // health.clipboardhealth.com
                  "5e3b93ce137e998b5aeb3941", // biofourmis.com
                  "5e3a8f1b040ca7b0c6f069e6", // stord.com
                  "5e3a8f1c040ca7b0c6f0859d", // optibus.com
                  "5e3b8d41137e998b5ae18884", // equashield.com
                  "6167b913f52909a9cc9bdd38", // jokr.com
                  "5e3a8f47040ca7b0c6f104b4", // cava.com
                  "5e3a8f19040ca7b0c6f043c1", // intercom.com
                  "5e3bc0cb00c8f4c966a63a60", // ovoenergy.com
                  "5e3bbf4d00c8f4c966a177fb", // huisuanzhang.com
                  "5e3bc3cd00c8f4c966b10464", // wtoip.com
                  "5e3bc04d00c8f4c966a46575", // peoplecert.org
                  "629cc10acf28682e15f0b2ca", // gbtrnd.com
                  "5e3bbf2a00c8f4c966a11d86", // jmgo.com
                  "5e3a8f1b040ca7b0c6f061fe", // bigid.com
                  "5e3bbc4300c8f4c966989cfa", // kuaikanmanhua.com
                  "5e3a8f19040ca7b0c6f02f05", // marshmallow.com
                  "5e3a8f47040ca7b0c6f0fd1a", // joinhonor.com
                  "61acc8a6d0efcf20c6bf7452", // mythical.games
                  "5e3a8fa1040ca7b0c6f2dbac", // incode.com
                  "5e3a8f19040ca7b0c6f03604", // tackle.io
                  "5e3a7f1b0aa7a3270a551220", // flipdish.com
                  "5e3bc20500c8f4c966aa9da9", // route.com
                  "5e3a8f19040ca7b0c6f034ce", // captivateiq.com
                  "601c182b4ddab8d8bff1deab", // talos.com
                  "5fc742cfbf7e53924dedc9d3", // airslate.com
                  "5e3ba30500c8f4c9668dc813", // classdojo.com
                  "5e3b9189137e998b5ae8c876", // brewdog.com
                  "5e3bbe0f00c8f4c9669dd804", // enflame-tech.com
                  "5e3bc0ca00c8f4c966a62efc", // shulanhealth.com
                  "5e3a8f19040ca7b0c6f030d8", // seatgeek.com
                  "5e3b8d41137e998b5ae1884d", // eqrx.com
                  "6022661a4ddab8d8bf96cf07", // nexii.com
                  "636378bdaab093538e038e6e", // indrive.com
                  "624b2649e4e2dbb8bd0e232d", // spiber.inc
                  "5e3a8f19040ca7b0c6f03821", // useinsider.com
                  "5e3a8f71040ca7b0c6f197c2", // kallyope.com
                  "60c1e8835e744335aae82f2e", // replit.com
                  "6180d80eee1af0b5ab71d317", // ournextenergy.com -> one.ai
                  "5e3bc08c00c8f4c966a5455d", // yiguo.com
                  "606ef85e6a1d545278137944", // workhuman.com
                  "5e3a8fa3040ca7b0c6f30f8a", // qumulo.com
                  "5e3bc02500c8f4c966a3c4ee", // tealium.com
                  "5e3bc6a400c8f4c966b42bd7", // public.com
                  "NULL", // pipacoding.com
                  "5e3a8f1d040ca7b0c6f09135", // pilot.com
                  "5e3a8f74040ca7b0c6f1e67a", // aibee.com
                  "5e3a8f1f040ca7b0c6f0c941", // vectra.ai
                  "60c2113c5e744335aaf3e577", // ada.cx
                  "5fc7436fbf7e53924df1df8b", // smartmore.com
                  "60f052767d096fb914ba13b6", // rohlik.cz
                  "5e3a8f48040ca7b0c6f1239a", // floqast.com
                  "5e3a8f1b040ca7b0c6f06921", // mindtickle.com
                  "5e3a8f1a040ca7b0c6f050b7", // dailypay.com
                  "5e3a8f1a040ca7b0c6f04719", // offchainlabs.com
                  "5e3b65e8ab6348f63cd9e404", // copado.com
                  "5e3a8f48040ca7b0c6f12639", // gem.co
                  "5e3b93fd137e998b5aeb4412", // cardekho.com
                  "5e3bbdb900c8f4c9669d0cc3", // myglamm.com
                  "5f8549124b03336c9c9399e9", // agentsync.io
                  "60902f9cdb60992662c7eaf4", // merama.io
                  "606f18a96a1d54527854e700", // seekout.com
                  "5e3a8f49040ca7b0c6f13c33", // bigpanda.io
                  "67ddbccacf728449638f3daf", // phantom.app
                  "5e3bbed400c8f4c966a014fa", // livspace.com
                  "5e3a8f1b040ca7b0c6f063d7", // helium.com
                  "5e3b65e8ab6348f63cd9e65f", // choco.com
                  "5e3a8f19040ca7b0c6f02e77", // viz.ai
                  "5fd2d268165cb2139226a1c8", // unit.co
                  "5e3bc8ff00c8f4c966b5ef18", // storicard.com
                  "63637975aab093538e0b7e10", // spectrummed.com
                  "60b828545e744335aa071ac9", // swiftly.com
                  "615ae2e9fb22921ca3317d5e", // colossal.com
                  "5e3b8ede137e998b5ae44985", // mediheal.com
                  "5e3bbc1100c8f4c966980010", // mininglamp.com
                  "5e3bbf6c00c8f4c966a1e6ec", // igetget.com
                  "5e3bc3c200c8f4c966b0d622", // yimidida.com
                  "606f19916a1d54527856f660", // modernhealth.com
                  "5e3bc5fe00c8f4c966b398f6", // irl.com
                  "5e3bc29600c8f4c966acc39e", // tuhu.cn
                  "620b4395d1fb898462f7810a", // younited-group.com
                  "604228d14ddab8d8bf288489", // source.co
                  "5e3a8f48040ca7b0c6f10b8b", // cloudbees.com
                  "5e3bbe3900c8f4c9669e387c", // lifemiles.com
                  "5e3bc35600c8f4c966af56d6", // venafi.com
                  "5e3a8f19040ca7b0c6f03248", // guideline.com
                  "5e7aadfe49aa2858c0f8aa55", // rebelliondefense.com
                  "61d56bdddb415a1b7f02f670", // elemy.com
                  "5e3bbd3a00c8f4c9669b93e3", // happymoney.com
                  "5e3b9e22137e998b5aee1e2a", // dana.id
                  "5fc743f2bf7e53924df59fa4", // teld.cn
                  "5e3a8f1b040ca7b0c6f05f5b", // at-bay.com
                  "606ef78d6a1d545278119dd9", // visbymedical.com
                  "5e3bbd5400c8f4c9669bd3a0", // invoca.com
                  "5e3a8f19040ca7b0c6f03caa", // wasabi.com
                  "5e3b65e8ab6348f63cd9e552", // tango.me
                  "5e3b8ca2137e998b5adfc960", // appdirect.com
                  "5e3bbfdd00c8f4c966a310da", // juanpi.com
                  "5e3bc0c600c8f4c966a612e2", // ovhcloud.com
                  "5e3b65e8ab6348f63cd9e407", // getyourguide.com
                  "5e3bbea000c8f4c9669f50ef", // ivalua.com
                  "5e3a8f1d040ca7b0c6f096a2", // sisense.com
                  "5e3bc0a300c8f4c966a599a6", // pharmapacks.com
                  "5e3a8f48040ca7b0c6f10d6c", // sennder.com
                  "5e3b65e8ab6348f63cd9e696", // nexthink.com
                  "5e3a7f160aa7a3270a54cbb1", // zego.com
                  "5e3bc15400c8f4c966a832bf", // rightwayhealthcare.com
                  "5e3a8f1b040ca7b0c6f06906", // sunbit.com
                  "5e3bc2b300c8f4c966ad1650", // qingting.fm
                  "5e3bc5e900c8f4c966b3880c", // g2.com
                  "629cc0e1cf28682e15efec97", // caribou.com
                  "5e3bbf5200c8f4c966a187ee", // nowports.com
                  "5e3a8f1b040ca7b0c6f06ae5", // people.ai
                  "61acc855d0efcf20c6bf45f6", // carousell.sg
                  "5edf30d982c67200733e3159", // apna.co
                  "5e3a8f4a040ca7b0c6f15070", // karat.com
                  "5e3ba08c00c8f4c9668b41f0", // enpal.de
                  "5e3a8f1c040ca7b0c6f08eb6", // acko.com
                  "5e3a8f72040ca7b0c6f1bef7", // daily-harvest.com
                  "61fcf58a62adc22ead712a23", // thebankoflondon.com
                  "5e3a8f4a040ca7b0c6f15769", // fundbox.com
                  "5e3a8f1a040ca7b0c6f05b36", // turing.com
                  "6108a795bf896774a515e861", // globalbees.com
                  "5e3bbcfe00c8f4c9669b07f4", // leadschool.in
                  "5e3ba13b00c8f4c9668bf87f", // esentire.com
                  "5eb38537dd18f7e0684b8cc8", // beyondidentity.com
                  "5e3a8f1d040ca7b0c6f09968", // staffbase.com
                  "5e3bc81f00c8f4c966b549c1", // chief.com
                  "5e3a8f19040ca7b0c6f0308c", // intelycare.com
                  "NULL", // teleport.io
                  "5e3a8f1e040ca7b0c6f0aaf9", // imply.io
                  "606f56f36a1d545278ee4887", // material.security
                  "62ea315a542c2517a47538ea", // pw.live
                  "61d5cd7ddb415a1b7f406f5d", // recoverfiber.com
                  "5e3bc2c200c8f4c966ad3e61", // purplle.com
                  "5e3b9274137e998b5aea4e8a", // caisgroup.com
                  "646aef701735ef7dec74046f", // gaussianrobotics.com
                  "5e3bbfdd00c8f4c966a311bf", // nxin.com
                  "5e3bc2de00c8f4c966adafc3", // uisee.com
                  "5e3a8f9f040ca7b0c6f29c9b", // 56pingtai.net
                  "5f8548f84b03336c9c92ecca", // drinkcirkul.com
                  "5e3bc31900c8f4c966ae8623", // radiuspaymentsolutions.com
                  "5e3bc19300c8f4c966a92746", // rivigo.com
                  "6576fbf9f5c870dd55a49f13", // rivigo.com
                  "5e3b9c1c137e998b5aed89f2", // mamaearth.in
                  "5e3bbc5a00c8f4c96698f5fc", // mobilecoin.com
                  "5e3a8f19040ca7b0c6f02f80", // 0x.org
                  "5e3bbeff00c8f4c966a087ef", // jiuxian.com
                  "5e3a8f1b040ca7b0c6f0696e", // instabase.com
                  "5e3a8f4c040ca7b0c6f17e31", // sendbird.com
                  "655e111e5fcaf92a8bb00ac8", // density.exchange
                  "5e3b65e8ab6348f63cd9e76e", // mixpanel.com
                  "5e3b9201137e998b5ae99088", // ourbranch.com
                  "5e3b8e9d137e998b5ae3da12", // matrixport.com
                  "5e3bc07300c8f4c966a4edb8", // orcam.com
                  "NULL", // bcmaterial.com
                  "606f7e036a1d54527806d8f3", // ample.com
                  "5e3a8f1d040ca7b0c6f0a3c9", // blackbuck.com
                  "5e3bbd8a00c8f4c9669c8b4d", // leapmotor.com
                  "5ed1cf8b4ae879e7872a006a", // wheel.com
                  "5e3a8f1b040ca7b0c6f06907", // flexe.com
                  "5e3b65e8ab6348f63cd9ecc3", // hailo.ai
                  "62c2c6100405ea8a58c1a03b", // avenue.one
                  "5e3a7f200aa7a3270a554428", // deepl.com
                  "615c544cbbc70f4b89bd31df", // percent.com
                  "5e3bc14300c8f4c966a7ea3f", // palmetto.com
                  "615acf2ffb22921ca32178fd", // mnt-halan.com
                  "6042552e4ddab8d8bf41e2d4", // prove.com
                  "63732cbcaab093538ec9021e", // character.ai
                  "5e3a8f1c040ca7b0c6f07723", // atmosphere.tv
                  "6281ff5de3fea7b37084697e", // adept.ai
                  "5e3a8f1c040ca7b0c6f0783a", // beamery.com
                  "5e3a8f19040ca7b0c6f0342a", // flow.io
                  "5e3a8f4b040ca7b0c6f16f02", // xanadu.ai
                  "633ac8995732d6a1e43d9ae7", // lmwn.com
                  "611e5a35140e3df2cb6073b4", // celestia.org
                  "624e91cee55c24d5a4421c19", // stability.ai
                  "5e3b90c4137e998b5ae77a99", // vesttoo.com
                  "5e3a7f150aa7a3270a54c009", // satispay.com
                  "5e3a8f1a040ca7b0c6f04649", // paystand.com
                  "5fc7442dbf7e53924df75264", // turntide.com
                  "5e3b65e8ab6348f63cd9e75b", // ecovadis.com
                  "5e3bc1e800c8f4c966aa53cb", // transfermate.com
                  "6576faf0f5c870dd55a49b61", // lookout.com
                  "5e3a8f73040ca7b0c6f1e0a4", // lookout.com
                  "5e3a8f19040ca7b0c6f03230", // perimeter81.com
                  "5e3bc14300c8f4c966a7f48b", // snapdeal.com
                  "5e3bbf9200c8f4c966a2519d", // techstylefashiongroup.com
                  "6191d83a316b665c573762c7", // zebec.io
                  "5e3bbc3800c8f4c966986815", // inmobi.com
                  "5e3bc3b300c8f4c966b0958b", // wifi.com
                  "5e3bc09700c8f4c966a57107", // redventures.com
                  "5e3b924c137e998b5aea101d", // beibei.com
                  "5e3bbcd600c8f4c9669a7e5f", // lamabang.com
                  "NULL", // jimu.com
                  "5e3ba4b100c8f4c966908d70", // fxiaoke.com
                  "5e3ba4b100c8f4c966908f02", // mia.com
                  "5e3b9e4c137e998b5aee2a8c", // daojia.com
                  "5e3a8f74040ca7b0c6f1e5af", // daojia.com
                  "5e3bc3cb00c8f4c966b0fa8e", // womai.com
                  "5e3bbf1200c8f4c966a0be76", // hujiang.com
                  "5e3a8f19040ca7b0c6f03f8d", // itutorgroup.com
                  "5e3b65e8ab6348f63cd9e6e3", // mindmaze.com
                  "5e3ba31200c8f4c9668ddda1", // icarbonx.com
                  "5e3ba34a00c8f4c9668e2f40", // kendrascott.com
                  "5e3bbc8f00c8f4c96699af8d", // mobvoi.com
                  "5e3bc3e300c8f4c966b154a6", // zhuanzhuan.com
                  "5e3b8e1e137e998b5ae30248", // modmed.com
                  "6576fb08f5c870dd55a49bb4", // modmed.com
                  "5e3bc3d600c8f4c966b11aac", // zhaogang.com
                  "5e3b912b137e998b5ae82330", // dianrong.com
                  "5e3b98a6137e998b5aec8761", // cellc.co.za
                  "5e3bc3cb00c8f4c966b0f9ee", // yhglobal.com
                  "5e3bc12600c8f4c966a784f0", // revolutionprecrafted.com
                  "5e3bc39100c8f4c966b01afe", // welab.co
                  "5e3a8f48040ca7b0c6f11052", // maimai.cn
                  "5e3b92c3137e998b5aeacea9", // dxy.cn
                  "5e3a8f1a040ca7b0c6f05c83", // hmdglobal.com
                  "NULL", // huike.com
                  "5e3b9f74137e998b5aee7c1f", // chinac.com
                  "5e3bbe8400c8f4c9669ef7ba", // linkdoc.com
                  "5e3ba3bf00c8f4c9668ee88a", // mediamath.com
                  "5e3bbcdb00c8f4c9669a8f93", // movile.com
                  "5e3a8f19040ca7b0c6f03582", // patmcgrath.com
                  "5e3bc36c00c8f4c966af9c52", // wacai.com
                  "5e3ba2a300c8f4c9668d6a2b", // ishansong.com
                  "61d56bfadb415a1b7f03227b", // ebanma.com
                  "5e3bc24d00c8f4c966abb308", // tresata.com
                  "606f13736a1d54527849b626", // momenta.ai
                  "624b272fe4e2dbb8bd2504ff", // hosjoy.com
                  "5e3bc5de00c8f4c966b37d00", // omio.com
                  "606f1bd86a1d5452785df9a0", // tslsmart.com -> terminusgroup.com
                  "5e3a8f75040ca7b0c6f206f8", // bitfury.com
                  "5e3bc13a00c8f4c966a7cfd9", // reeftechnology.com
                  "5e3a8f1e040ca7b0c6f0aea6", // globality.com
                  "5e3b65e8ab6348f63cd9ec5c", // ynsect.com
                  "5e3bbcca00c8f4c9669a58c9", // intellif.com
                  "606f1d826a1d545278629bd3", // hk.poizon.com
                  "5e3bc0f700c8f4c966a6c81e", // sitechdev.com
                  "5e3a8f46040ca7b0c6f0ee9f", // knowbox.cn
                  "606f0fee6a1d5452783e8d1a", // yanolja.com
                  "5e3a8f1c040ca7b0c6f07c78", // meero.com
                  "61e69627f2bbe0f6019239f8", // ibotta.com
                  "5e3b8ce5137e998b5ae0a56e", // c2fo.com
                  "5e3b65e8ab6348f63cd9eba2", // numbrs.com
                  "61de5eff4083114924ed959a", // ebanx.com
                  "5e3a8f19040ca7b0c6f036d6", // alto.com
                  "5e3bbf2400c8f4c966a0f8a0", // hotmart.com
                  "646aef091735ef7dec733bfa", // dubizzlegroup.com
                  "5e3b8f55137e998b5ae50179", // quizlet.com
                  "5e3cdbee00c8f4c966c3d1bf", // orcabio.com
                  "5e3b65e8ab6348f63cd9e468", // infobip.com
                  "5e3a8f19040ca7b0c6f02e4a", // zwift.com
                  "6225f7fb113379b22218b09f", // play.co
                  "5e3a8f19040ca7b0c6f0341b", // boomsupersonic.com
                  "5e3a8f1a040ca7b0c6f044f5", // quantummetric.com
                  "5e3bc89a00c8f4c966b5a404", // newsbreak.com
                  "5e3bbf7f00c8f4c966a2361a", // madeiramadeira.com.br
                  "5e3a7f280aa7a3270a55c5dc", // ppro.com
                  "5e3ba33400c8f4c9668e1355", // splashtop.com
                  "5e3ba22900c8f4c9668ce165", // flashexpress.com
                  "5e3a8f19040ca7b0c6f0407a", // axiomspace.com
                  "5e3a8f1a040ca7b0c6f046b4", // locusrobotics.com
                  "5e3a8f4b040ca7b0c6f16dc6", // standard.ai
                  "5e3ba4e000c8f4c96690e586", // webull.com
                  "5e3a8f77040ca7b0c6f24718", // newsela.com
                  "5e3b64d0ab6348f63cd991c1", // aquasec.com
                  "5e3b65e8ab6348f63cd9e702", // patsnap.com
                  "5e3a8f1f040ca7b0c6f0c512", // evidation.com
                  "5e3bc0bc00c8f4c966a5fcec", // sidecarhealth.com
                  "5e3b9bcc137e998b5aed70be", // feedzai.com
                  "5e3bc3ce00c8f4c966b108ee", // yxt.com
                  "5e3a8f19040ca7b0c6f02fa0", // cameo.com
                  "5e3bc30200c8f4c966ae29b0", // hyperchain.cn
                  "5e3a8f19040ca7b0c6f032f9", // thezebra.com
                  "5e3a8f19040ca7b0c6f0305a", // clearcover.com
                  "5e3bbc3f00c8f4c96698905e", // groq.com
                  "5e3a8f71040ca7b0c6f19a55", // fiture.com
                  "624b26b0e4e2dbb8bd181790", // injective.com
                  "5e3a8f45040ca7b0c6f0ce13", // sift.com
                  "6462035cf84a741556814f41", // teya.com
                  "5faa8b40c1c90b5227658bc6", // capsule.com
                  "5e3a8f48040ca7b0c6f10d27", // mux.com
                  "5e3b90f2137e998b5ae7c636", // xforceplus.com
                  "624b271fe4e2dbb8bd23aa9a", // augustinusbader.com
                  "5e3a7f150aa7a3270a54c321", // shift-technology.com
                  "5e3a8fa1040ca7b0c6f2c9bd", // tenstorrent.com
                  "5fc743f2bf7e53924df59b21", // zihaiguo.com
                  "5e3a8fc8040ca7b0c6f379c2", // axtria.com
                  "5e3a8f48040ca7b0c6f12635", // amount.com
                  "5ed1cf8b4ae879e7872a002c", // vise.com
                  "5e3a8f1a040ca7b0c6f04436", // printful.com
                  "5e3a8f1b040ca7b0c6f05f8f", // goshippo.com
                  "5e3a8f19040ca7b0c6f03122", // thirtymadison.com
                  "5e3a8f1c040ca7b0c6f08a13", // letsgetchecked.com
                  "61de5f0d4083114924eda80d", // ecoflow.sg
                  "6436a9450e469875f36d0997", // carro.co
                  "5e3a7f150aa7a3270a54c7b3", // tractable.ai
                  "5e3b90ad137e998b5ae75379", // xiaoe-tech.com
                  "5e3a8f19040ca7b0c6f03430", // bringg.com
                  "5e3a8f1b040ca7b0c6f05cd6", // claroty.com
                  "62ea2d72542c2517a45f658f", // hellosimply.com
                  "5e3a8f1e040ca7b0c6f0b973", // aircall.io
                  "6180f0e1ee1af0b5ab7f9e22", // worldcoin.org
                  "5e3a8f1a040ca7b0c6f0483a", // smartasset.com
                  "5e3a8f19040ca7b0c6f036d3", // morningconsult.com
                  "5e3b64d0ab6348f63cd99289", // visier.com
                  "5e3a8f1a040ca7b0c6f04433", // shipbob.com
                  "5e3a8f19040ca7b0c6f03218", // amperity.com
                  "5f8549864b03336c9c96e624", // joinpantheon.io
                  "61082557ba073244e19aec22", // xiaoice.com
                  "5e3b8db1137e998b5ae24912", // dmedglobal.com
                  "5e3b96b5137e998b5aebfd3d", // carsongroup.com
                  "5e3bbd6f00c8f4c9669c3bfe", // interos.ai
                  "606f0ed56a1d5452783bf712", // nium.com
                  "5e3a8f19040ca7b0c6f0323d", // humaninterest.com
                  "5e3bc26100c8f4c966abfd21", // bluecore.com
                  "5e3ba4da00c8f4c96690df82", // freshbooks.com
                  "5f35f67171eeac40b62c1940", // snorkel.ai
                  "5e3b65e8ab6348f63cd9e69c", // gelato.com
                  "5e3a8f1c040ca7b0c6f07642", // mavenclinic.com
                  "5e3a8f19040ca7b0c6f03e7d", // picsart.com
                  "5e3a8fcb040ca7b0c6f3c76c", // 1kmxc.com
                  "653769bfd1653b1e73610c8f", // shopcider.com
                  "6090171ddb60992662c496dd", // berlinbrandsgroup.com -> berlin-brands-group.com
                  "5e3a8f9d040ca7b0c6f256fe", // agilerobots.com -> agile-robots.com
                  "6436a9450e469875f36d1527", // mammothdiagnostics.com
                  "5eb38533dd18f7e0684b66b1", // orchard.com
                  "5e3a8f19040ca7b0c6f02f3a", // xendit.co
                  "5e3a7f250aa7a3270a559494", // truelayer.com
                  "5eb38522dd18f7e0684acc1c", // withassembly.com
                  "5e3b65e8ab6348f63cd9e56a", // pandadoc.com
                  "5e68b9682bcca6b0a5850f52", // keenonrobot.com
                  "NULL", // haydonkerkpittman.com
                  "6436a9450e469875f36d0998", // ninjavan.com
                  "5e3a8f19040ca7b0c6f03177", // vedantu.com
                  "5e3a8f76040ca7b0c6f21fe3", // trialspark.com
                  "5e3a8f19040ca7b0c6f03a26", // ajaib.co.id
                  "5e3bbe0700c8f4c9669dd04a", // licious.in
                  "5e3ba16400c8f4c9668c1171", // masterworks.com
                  "5e3b64d0ab6348f63cd99227", // chronosphere.io
                  "5e3a8f1b040ca7b0c6f063d3", // solo.io
                  "5e3bc29400c8f4c966acacce", // savagex.com
                  "5f76ba8aa2734681ce2c22da", // swile.co
                  "5e3bbc4900c8f4c96698c1e6", // mobikwik.com
                  "606f1a456a1d54527859505d", // wandb.ai
                  "5e3b65e8ab6348f63cd9e814", // zopa.com
                  "5e3a8f72040ca7b0c6f1b4b5", // dental-monitoring.com
                  "5e3b94bb137e998b5aeb7603", // cargox.com.br
                  "5e3bc85b00c8f4c966b576db", // getfabric.com
                  "5e3a8f47040ca7b0c6f10148", // augury.com
                  "5e3bbcbe00c8f4c9669a3011", // mokahr.com
                  "5e3bc0bb00c8f4c966a5f306", // tezign.com
                  "61acc859d0efcf20c6bf48bc", // vagaro.com
                  "5f851b154b03336c9c7b45d9", // razor-group.com
                  "5e3ba3af00c8f4c9668ed9bf", // igaworks.com
                  "6090208cdb60992662c5ee78", // contrastsecurity.com
                  "5e3a8f1b040ca7b0c6f05cb8", // contrastsecurity.com
                  "5e3a8f19040ca7b0c6f03112", // wrapbook.com
                  "60e6ac337d096fb914dff84c", // mensabrands.com
                  "6399c78da1d483d563ee5504", // heyday.hootsuite.com
                  "60e70ff57d096fb91404914b", // place.com
                  "5e3a8f49040ca7b0c6f129ea", // stytch.com
                  "5e3a8f47040ca7b0c6f103e0", // owkin.com
                  "63a2c603a1d483d5633b7956", // expel.com
                  "5f76ba8ba2734681ce2c25d5", // nobroker.in
                  "5ff7c8d41c505c0263fa0636", // lessen.com
                  "5e3a8fc9040ca7b0c6f38414", // clara.com
                  "5e3bc3c200c8f4c966b0d58f", // yipitdata.com
                  "5f8548cd4b03336c9c91ae30", // anyscale.com
                  "5edf2f4082c67200733cb7d6", // iodinesoftware.com
                  "5e3bc0d500c8f4c966a654bc", // reliaquest.com
                  "5e3bc09d00c8f4c966a581a8", // petcircle.com.au
                  "606f1aea6a1d5452785b484f", // naturesfynd.com
                  "5e3a7f150aa7a3270a54c524", // lydia-app.com
                  "606f60cd6a1d545278f24d27", // sellerx.com
                  "5e3bc15c00c8f4c966a8485b", // snaplogic.com
                  "61319479991d47f5fee3f7e9", // cadence.care
                  "601b8af04ddab8d8bfaa9e2d", // nonamesecurity.com
                  "5e3a8f1a040ca7b0c6f0478d", // rothys.com
                  "5e3bc32900c8f4c966aebc32", // voi.com
                  "61d5c35fdb415a1b7f3e655c", // haomo.ai
                  "5e3bbc5f00c8f4c96698ff7d", // kopikenangan.com
                  "5e3bc89a00c8f4c966b5a72d", // infarm.com
                  "653769c0d1653b1e73610eee", // groupone-holdings.com
                  "5e3ba3fe00c8f4c9668f4c53", // fractal.ai
                  "5e3a8fa4040ca7b0c6f31f34", // assent.com
                  "5e3a8f4a040ca7b0c6f14391", // assent.com
                  "5e3a8f76040ca7b0c6f224da", // placer.ai
                  "60efef807d096fb9146ff68d", // pentera.io
                  "5e3ba0ea00c8f4c9668ba349", // darwinbox.com
                  "5e3a8f1b040ca7b0c6f06817", // min.io
                  "5fd25cee165cb2139209c903", // esusurent.com
                  "5e3b92d5137e998b5aeaebdb", // betterfly.com
                  "634e1b22f5f3a166943461c8", // dune.com
                  "5e3a8f19040ca7b0c6f041f3", // scandit.com
                  "5e955bab52238f094e76cb69", // payhawk.com
                  "642a59d41730d797537e0c88", // wearewatershed.com
                  "606f573e6a1d545278ee66f1", // axelar.network
                  "5e3a8f71040ca7b0c6f1a46b", // cheq.ai
                  "5e3b64d0ab6348f63cd991fb", // hasura.io
                  "5e3bc21500c8f4c966aadfa1", // timescale.com
                  "60226a174ddab8d8bf98cd7c", // scalapay.com
                  "5e3bc02d00c8f4c966a3dfe6", // omadahealth.com
                  "5e3b9a83137e998b5aed1045", // bluevoyant.com
                  "5e3b90cb137e998b5ae78782", // veev.com
                  "5e3bc34b00c8f4c966af2c9f", // vendr.com
                  "64bae500507037b64d88f026", // gauntlet.network -> gauntlet.xyz
                  "621f974c972ef7e5d69cc330", // aptoslabs.com
                  "5e3a8f1c040ca7b0c6f087c9", // glia.com
                  "5e3b8d46137e998b5ae1978b", // commerceiq.ai
                  "65d3a4e1a88abbdf5b6ce571", // rapidapi.com
                  "5e3a8f19040ca7b0c6f036f3", // rapidapi.com
                  "5e3bbfe000c8f4c966a32519", // taranawireless.com
                  "5e3a8f46040ca7b0c6f0e447", // flashparking.com
                  "5e3a8f1c040ca7b0c6f07c74", // electric.ai
                  "5e3a8f1a040ca7b0c6f04fc7", // bostongene.com
                  "5e3bc5b300c8f4c966b331f5", // grover.com
                  "5e3a8f45040ca7b0c6f0d415", // genies.com
                  "5eb38535dd18f7e0684b7882", // oysterhr.com
                  "5e3a8f46040ca7b0c6f0e38e", // nexhealth.com
                  "616447fbc3c073075bd53e3d", // upsidefoods.com
                  "5e3a7f150aa7a3270a54c2b3", // factorialhr.com
                  "5e3a8f9f040ca7b0c6f294c1", // divergent3d.com
                  "5e3a8f19040ca7b0c6f03169", // taxfix.de
                  "651d2ca548a2fc3af451f712", // openvc.com
                  "61ba1f6ed5ee889fe061e50b", // polarium.com
                  "61644462c3c073075bd1edf1", // opn.ooo
                  "5e3a7f2b0aa7a3270a55efaf", // remarkable.com
                  "5e3b8df3137e998b5ae2bb6f", // habi.co
                  "61644138c3c073075bcefc6d", // glean.com
                  "5e3b949f137e998b5aeb6e81", // carebridgeinc.com
                  "5e3a8f1b040ca7b0c6f0755a", // immuta.com
                  "5f851aec4b03336c9c7ae6f6", // jupiterone.com
                  "5e3bc36500c8f4c966af7cc0", // vayyar.com
                  "5e3bbd7d00c8f4c9669c6127", // leadsquared.com
                  "5e3a8f49040ca7b0c6f12e01", // fourkites.com
                  "5ef2c5cc0eb04dccca71ea77", // vulcanforms.com
                  "606ef8056a1d54527812d83d", // singlestore.com
                  "5e3bc30500c8f4c966ae456a", // unstoppabledomains.com
                ],
              ],
            ],
          },
          searchId: 2121,
        },
        {
          label: "Founders ex-Tech Corporates 🏢",
          query: {
            SignalDate: DATE_RANGE_OPTIONS.THIS_WEEK,
            SignalType: ["New Company"],
            Companies: [
              "Past",
              [
                "OR",
                [
                  "6576fbfcf5c870dd55a49f1f", // apple.com
                  "5e6afb206e2d0842bf48218a", // apple.com
                  "6576fcfdf5c870dd55a4a2bd", // apple.com
                  "5e3b921e137e998b5ae9bdef", // beatsbydre.com
                  "5e3b8f2e137e998b5ae4bebd", // microsoft.com
                  "6576fa03f5c870dd55a4982d", // microsoft.com
                  "6576fc64f5c870dd55a4a09a", // linkedin.com
                  "5f48c4252bb9614168f9a230", // linkedin.com
                  "6576fc1bf5c870dd55a49f93", // linkedin.com
                  "6576fbbbf5c870dd55a49e36", // linkedin.com
                  "5e3a8f19040ca7b0c6f04072", // abc.xyz
                  "5e3a8f1a040ca7b0c6f0521b", // youtube.com
                  "5e3a8f9d040ca7b0c6f25b63", // google.com
                  "6576fa7af5c870dd55a499c3", // google.com
                  "6576fbb2f5c870dd55a49e12", // amazon.com
                  "5e3a8f4b040ca7b0c6f166cb", // amazon.com
                  "5e3a8f19040ca7b0c6f0308b", // aws.com
                  "5e3bbf4d00c8f4c966a17548", // nvidia.com
                  "5e3bc0d100c8f4c966a6435e", // tesla.com
                  "61acc832d0efcf20c6bf2812", // meta.com
                  "5e3b8cb5137e998b5ae01416", // facebook.com
                  "5e3a7f1d0aa7a3270a5528b7", // instagram.com
                  "5e3bc39200c8f4c966b02074", // whatsapp.com
                  "5e3bc29400c8f4c966acacf4", // tsmc.com
                  "5e3bc0ae00c8f4c966a5c540", // tencent.com
                  "5e3b919e137e998b5ae8e5c8", // broadcom.com
                  "6576fa09f5c870dd55a49843", // broadcom.com
                  "5e3b92ad137e998b5aeab36e", // samsung.com
                  "6576fbb8f5c870dd55a49e2b", // samsung.com
                  "5e3b8f7b137e998b5ae55426", // oracle.com
                  "5e3b90c3137e998b5ae77708", // asml.com
                  "5e3a8f1d040ca7b0c6f09b3b", // alibaba.com
                  "6576fb79f5c870dd55a49d46", // adobe.com
                  "5e3a8f72040ca7b0c6f1c5a0", // adobe.com
                  "604266034ddab8d8bf487b4c", // adobe.com
                  "5e3b92c2137e998b5aeac8f3", // cisco.com
                  "5e3b95d8137e998b5aebc466", // salesforce.com
                  "6576fc03f5c870dd55a49f3a", // salesforce.com
                  "5e3bbd8d00c8f4c9669c8c02", // netflix.com
                  "5e3a8f19040ca7b0c6f03a56", // amd.com
                  "5e3bc29900c8f4c966acde66", // sap.com
                  "6576fbeaf5c870dd55a49edf", // sap.com
                  "6576fb51f5c870dd55a49cb9", // sap.com
                  "6576f9fdf5c870dd55a49822", // sap.com
                  "5e3bc0aa00c8f4c966a5bc90", // ti.com
                  "5e3bbcb900c8f4c9669a14cb", // intel.com
                  "5e3b8eab137e998b5ae3e915", // intuit.com
                  "5e3a8f1d040ca7b0c6f0967e", // ibm.com
                  "6576fa98f5c870dd55a49a2f", // ibm.com
                  "6576fadef5c870dd55a49b22", // ibm.com
                  "5e3bc2d900c8f4c966ad891b", // qualcomm.com
                  "5e3a8f48040ca7b0c6f10e78", // appliedmaterials.com
                  "5e3bc33400c8f4c966aeea53", // appliedmaterials.com
                  "5e3bc0ea00c8f4c966a6992e", // pinduoduo.com
                  "5e3b9003137e998b5ae63fff", // servicenow.com
                  "5e48fce7cee276666c345a5f", // meituan.com
                  "5e3bc1b900c8f4c966a99b4f", // sony.com
                  "5e3ba4ac00c8f4c96690843f", // keyence.com
                  "5e3b9c81137e998b5aeda629", // booking.com
                  "5e3a8f1a040ca7b0c6f0550f", // adp.com
                  "5e3bc2c200c8f4c966ad41ae", // se.com
                  "5e3b905f137e998b5ae6dbd4", // se.com
                  "6576fc41f5c870dd55a4a01e", // se.com
                  "5e3bc2ce00c8f4c966ad6612", // se.com
                  "6576fa74f5c870dd55a499ae", // se.com
                  "6576fa2ef5c870dd55a498c0", // uber.com
                  "6576fa02f5c870dd55a4982a", // uber.com
                  "5e3bc29900c8f4c966ace2f7", // uber.com
                  "5e3a8f4c040ca7b0c6f17bbd", // analog.com
                  "5e3a8f71040ca7b0c6f19b89", // analog.com
                  "5e3bbcd700c8f4c9669a8609", // lamresearch.com
                  "5e3a8f1a040ca7b0c6f04e7e", // airbnb.com
                  "5e3bc07400c8f4c966a4fdd5", // shopify.com
                  "6576fb12f5c870dd55a49bd5", // shopify.com
                  "5e3a8f19040ca7b0c6f043e7", // fiserv.com
                  "6576fa16f5c870dd55a4986d", // fiserv.com
                  "6576fbb2f5c870dd55a49e14", // fiserv.com
                  "5e3bbb8f00c8f4c96696d2d4", // micron.com
                  "6576fa29f5c870dd55a498b1", // micron.com
                  "5e3bc03500c8f4c966a422e7", // paypal.com
                  "5e3a8f4c040ca7b0c6f17656", // activisionblizzard.com
                  "5e3bc12d00c8f4c966a79a80", // paloaltonetworks.com
                  "6576fab3f5c870dd55a49a92", // paloaltonetworks.com
                  "NULL", // netease.com
                  "5e3ba20900c8f4c9668cbc01", // equinix.com
                  "5e3bc07400c8f4c966a4fa1b", // tel.com
                  "5e3bc33c00c8f4c966af01d2", // vmware.com
                  "6576fa52f5c870dd55a49939", // kla.com
                  "5e3bc0f700c8f4c966a6cc9f", // skhynix.com
                  "5e3a8f1f040ca7b0c6f0c5fd", // synopsys.com
                  "6576fc02f5c870dd55a49f36", // synopsys.com
                  "5e3b8eab137e998b5ae3e880", // mercadolibre.com
                  "5e3b9259137e998b5aea23f2", // cadence.com
                  "5e3bbeff00c8f4c966a08402", // jd.com
                  "606f1da86a1d545278630198", // jd.com
                  "6576fa38f5c870dd55a498e5", // workday.com
                  "5e3ba2d300c8f4c9668d8de6", // fortinet.com
                  "5e3b9ef6137e998b5aee5941", // 3ds.com
                  "6576fc2ff5c870dd55a49fde", // 3ds.com
                  "5e3bbfdd00c8f4c966a3117c", // nxp.com
                  "5e3a8f73040ca7b0c6f1ce69", // arista.com
                  "5e3a8f19040ca7b0c6f031f8", // adyen.com
                  "6576fc85f5c870dd55a4a10d", // marvell.com
                  "6576fb97f5c870dd55a49db3", // marvell.com
                  "5e3b9ff5137e998b5aeea02d", // marvell.com
                  "5e3a8f19040ca7b0c6f0358a", // snowflake.com
                  "5e3b9189137e998b5ae8c808", // baidu.com
                  "5e3bbed700c8f4c966a01db8", // nintendo.com
                  "5e3bc1d400c8f4c966aa0531", // ropertech.com
                  "5e3bbc3800c8f4c9669868d7", // infineon.com
                  "6576fcb8f5c870dd55a4a1c1", // infineon.com
                  "6576fbb5f5c870dd55a49e1f", // infineon.com
                  "5e3bbbe800c8f4c9669781d8", // microchip.com
                  "5e3ba3ae00c8f4c9668ed77e", // foxconn.com
                  "5e3bc29800c8f4c966acd6bf", // st.com
                  "6576fac4f5c870dd55a49acb", // onsemi.com
                  "606f1ab56a1d5452785aa33b", // onsemi.com
                  "5e3bc03100c8f4c966a40682", // onsemi.com
                  "5e3bc2c200c8f4c966ad4198", // te.com
                  "6576fd2ff5c870dd55a4a36d", // te.com
                  "5e3a8f9f040ca7b0c6f2a5a6", // autodesk.com
                  "6576fd34f5c870dd55a4a380", // autodesk.com
                  "624e91e9e55c24d5a4423372", // block.xyz
                  "5e3a8fa2040ca7b0c6f2e211", // atlassian.com
                  "5e3b9280137e998b5aea664c", // csisoftware.com
                  "5e3bc1ae00c8f4c966a97fc9", // thetradedesk.com
                  "60226c3f4ddab8d8bf99eb65", // deltathailand.com
                  "5e3bc2fa00c8f4c966ae0f75", // iqvia.com
                  "6576fbaef5c870dd55a49e08", // iqvia.com
                  "5e3bc10a00c8f4c966a713b0", // palantir.com
                  "5e3ba19400c8f4c9668c4315", // dell.com
                  "5e3bc3c900c8f4c966b0e7ed", // mi.com
                  "5e3bbc1f00c8f4c966981e84", // kuaishou.com
                  "5e3b97e1137e998b5aec500d", // crowdstrike.com
                  "5e3bbd6f00c8f4c9669c37fe", // murata.com
                  "6576fab2f5c870dd55a49a90", // murata.com
                  "5e3ba23800c8f4c9668cf4ab", // mediatek.com
                  "5e3bbbe400c8f4c9669774ca", // sea.com
                  "606ef49b6a1d5452780bb848", // sea.com
                  "5e3a8f1a040ca7b0c6f052cb", // doordash.com
                  "5e3ba15700c8f4c9668c0942", // fisglobal.com
                  "6576fb3ef5c870dd55a49c75", // fisglobal.com
                  "5e3b9ec5137e998b5aee4b56", // datadoghq.com
                  "5e3b8dc7137e998b5ae26b27", // ea.com
                  "6576fc1df5c870dd55a49f9b", // ea.com
                  "5e3b9af9137e998b5aed32a5", // costargroup.com
                  "5e3bbf4400c8f4c966a14ed5", // symbotic.com
                  "5e3bc0eb00c8f4c966a6a04b", // renesas.com
                  "6576fbadf5c870dd55a49e05", // renesas.com
                  "5e3ba3d800c8f4c9668f0958", // globalfoundries.com -> gf.com
                  "5e3bbe0200c8f4c9669dc843", // hp.com
                  "6576fd0ef5c870dd55a4a2f8", // hp.com
                  "61644de0c3c073075bdbcfd1", // coupang.com -> aboutcoupang.com
                  "5e3a8f70040ca7b0c6f195b7", // amadeus.com
                  "5e3ba44c00c8f4c9668fc23f", // globalpaymentsinc.com
                  "5e3bc36600c8f4c966af8645", // veeva.com
                  "5e3bc3b800c8f4c966b0b360", // wolterskluwer.com
                  "6576fc65f5c870dd55a4a09b", // wolterskluwer.com
                  "60226ffe4ddab8d8bf9bec61", // wolterskluwer.com
                ],
              ],
            ],
          },
          searchId: 2122,
        },
        {
          label: "New YC Founders + Applicants 🍊",
          query: {
            TalentTags: ["contains", ["(A) YC", "YC S", "YC W"]],
          },
          searchId: 1638,
        },
      ],
    },
    "Layoff Tracker": {
      icon: PiSuitcaseSimple,
      searches: [
        {
          label: "Global Layoffs",
          query: {
            SignalType: ["Searching"],
          },
        },
      ],
    },
  },
  [SpecterProducts.stratintel]: {
    "Interest by Stage": {
      icon: PiTree,
      searches: [
        {
          label: "New Startups 🐥",
          query: {
            growthStage: ["pre_launch", "bootstrapped"],
          },
          searchId: 2165,
        },
        {
          label: "Early Stage 🪴",
          query: {
            growthStage: ["early"],
          },
          searchId: 2170,
        },
        {
          label: "Growth Stage 🌟",
          query: {
            growthStage: ["growing", "late"],
          },
          searchId: 2167,
        },
      ],
    },
    "Interest by Source": {
      icon: HiOutlineStar,
      searches: [
        {
          label: "Interest from Top VCs 🏆",
          query: {
            HQLocation: [
              // Europe
              '["144959613005987840:145241087982698496","147211412819673088:147492887796383744","288511851128422400:295548725546188800","295830200522899456:298363475313295360","298644950290006016:303992974847508480","437130638831648768:437412113808359424","576742227280134144:577023702256844800"]',
              // Israel
              "295548725546188800:295830200522899455",
            ],
            SignalDate: DATE_RANGE_OPTIONS.THIS_WEEK,
            growthStage: ["pre_launch", "bootstrapped", "early"],
            SignalSource: [
              "OR",
              [
                "inv_7fb1cbfa5cf171152320f2b4", // "New Enterprise Associates",
                "inv_9eb8496a579270b753955764", // "Y Combinator",
                "inv_5ed808adef8f158e9f38a98f", // "Intel Capital",
                "inv_84f563826c689ceb42aff52a", // "Sequoia Capital",
                "inv_68b0898a81f43beb970f8181", // "Accel",
                "inv_eec6dc9d78bc0ff28903f317", // "Kleiner Perkins",
                "inv_609dba55408c4490fe58cf8e", // "Bessemer Venture Partners",
                "inv_effc15c24648694f7e295fd7", // "Venrock",
                "inv_dfadc422e4dba47eb76bae49", // "Greylock",
                "inv_7d1f3d806987deaf05c04e89", // "Index Ventures",
                "inv_9e4763d7bb3bc7cfaade3667", // "Google Ventures",
                "inv_27ea6064e83463b91030970d", // "Lightspeed Venture Partners
                "inv_1d3c986f2d463149711b1e65", // "Battery Ventures",
                "inv_d9edb518713d429fa16bc3e3", // "Andreessen Horowitz",
                "inv_5c0fbb8db8fde4516cd25e4e", // "Insight Partners",
                "inv_fcb19d09d220c93dc8ea09ff", // "First Round Capital",
                "inv_94c1a1ccb97fe5234bd87e82", // "Benchmark",
                "inv_29af123b59ccf3aa3cfc5cb4", // "General Catalyst",
                "inv_4703839e7313a4bb5d2be789", // "Menlo Ventures",
                "inv_c6b1f7f870b49880eb11970d", // "CRV",
                "inv_8fb74dd3dfc7905fdb35dbfb", // "Felicis",
                "inv_d93f9201182de04afadd94a0", // "Khosla Ventures",
                "inv_028a895cdd9214c56b25165c", // "Canaan Partners",
                "inv_af408539449777927acff7f5", // "Lerer Hippeau",
                "inv_d6d4e2597ed84408a3a4d87e", // "Bossanova Investimentos",
                "inv_5ddbc79acd9907e9c3f3c327", // "Founders Fund",
                "inv_11731f4485e7dbf7d63882fe", // "Tiger Global Management",
                "inv_2d9d1b454804c544ac07f1c5", // "Greycroft",
                "inv_c582a5e377debb54b61b7237", // "Redpoint",
                "inv_9e52868d444d1992b963209f", // "SV Angel",
              ],
            ],
          },
          searchId: 1548,
        },
        {
          label: "Sequoia Tracker",
          query: {
            SignalSource: ["OR", ["inv_84f563826c689ceb42aff52a"]], // Sequoia Capital
          },
          searchId: 2847,
        },
      ],
    },
    "Hot Signals": {
      icon: HiOutlineFire,
      searches: [
        {
          label: "Hot Signals (90 days) 🔥",
          query: {
            HotSignals: "90 days",
          },
        },
        {
          label: "Hot Signals (180 days) 🔥",
          query: {
            HotSignals: "180 days",
          },
        },
        {
          label: "Hot Signals (360 days) 🔥",
          query: {
            HotSignals: "360 days",
          },
        },
        {
          label: "Hot Signals (720 days) 🔥",
          query: {
            HotSignals: "720 days",
          },
        },
      ],
    },
  },
  [SpecterProducts.investors]: {
    Highlights: {
      icon: HiOutlineSparkles,
      searches: keys(INVESTOR_HIGHLIGHTS_PROPS).map((option) => {
        const { colorScheme, label } =
          INVESTOR_HIGHLIGHTS_PROPS[option as InvestorHighlight]

        return {
          label: (
            <>
              <Box
                boxSize={2}
                bgColor={`${colorScheme}.400`}
                borderRadius="full"
              />
              {label}
            </>
          ),
          query: {
            Highlights: ["OR", [option]],
          },
        }
      }),
    },
  },
  [SpecterProducts.fundingRounds]: {
    "Latest Rounds": {
      icon: HiOutlineRocketLaunch,
      searches: {
        query: {
          announcedOn: DATE_RANGE_OPTIONS.PAST_30_DAYS,
        },
        sort: {
          raisedAmount: "desc",
        },
      },
    },
  },
  [SpecterProducts.acquisition]: {
    "Billion Dollar Acquisitions": {
      icon: HiOutlineCurrencyDollar,
      searches: {
        query: {
          acquisitionPrice: ["gte", 1_000_000_000],
        },
      },
    },
  },
  [SpecterProducts.ipo]: {
    "Recent IPOs": {
      icon: HiOutlineRocketLaunch,
      searches: {
        query: {
          wentPublicOn: DATE_RANGE_OPTIONS.PAST_30_DAYS,
        },
      },
    },
  },
}
