import { Text } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useParams } from "@remix-run/react"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import { z, ZodAny, ZodString, ZodTypeAny } from "zod"
import { AITag } from "~/components/AITag"
import {
  CompanyOperator,
  CompanyOperatorsField,
  COMPANY_OPERATORS,
  COMPANY_SIZE_OPTIONS,
  CURRENT_TENURE_OPTIONS,
  FUNDING_TYPE_OPTIONS,
  GROWTH_STAGE_OPTIONS,
  INDUSTRY_OPTIONS,
  LANGUAGE_PROFICIENCY_OPTIONS,
  PEOPLE_DEPARTMENTS,
} from "~/consts/signals"
import { isDateValid } from "./isDateValid"
import { isOneOf } from "./isOneOf"
import { TypedObjectKeysTuple, ValueOf } from "./types"
import { isNullish, Values } from "./values"

export const NUMERIC_OPERATORS_REVAMPED = {
  GTE: "gte",
  GT: "gt",
  EQUALS: "equals",
  LT: "lt",
  LTE: "lte",
  BETWEEN: "between",
} as const

type NumericFilterProps = {
  defaultValue?: ValueOf<typeof NUMERIC_OPERATORS>
  extraConstraints?: (num: z.ZodNumber) => z.ZodNumber
  allowNegatives?: boolean
}

export function numericFilterRevampedSchema({
  defaultValue = NUMERIC_OPERATORS_REVAMPED.GTE,
  extraConstraints = (num) => num,
  allowNegatives,
}: NumericFilterProps = {}) {
  const zodNumberSchema = extraConstraints(z.number().int())

  const zodNumberNegativeConstraint = allowNegatives
    ? zodNumberSchema
    : zodNumberSchema.nonnegative()

  const tupleSchema = z.tuple([
    z.nativeEnum(NUMERIC_OPERATORS_REVAMPED).default(defaultValue),
    zodNumberNegativeConstraint,
    zodNumberNegativeConstraint.nullable(),
  ])

  return z.union([
    tupleSchema,
    numericFilterSchema({
      defaultValue,
    }),
  ])
}

export function numericFilterSchema<TOmitBetween extends boolean = false>({
  defaultValue = NUMERIC_OPERATORS.GTE,
  extraConstraints = (num) => num,
  allowNegatives,
  omitBetween,
}: NumericFilterProps & {
  omitBetween?: TOmitBetween
} = {}) {
  const zodNumberSchema = extraConstraints(z.number().int())
  const zodNumberNegativeConstraint = allowNegatives
    ? zodNumberSchema
    : zodNumberSchema.nonnegative()

  const tupleSchema = z.tuple([
    z.nativeEnum(NUMERIC_OPERATORS).default(() => defaultValue),
    zodNumberNegativeConstraint,
  ])

  const unionSchema = z.union([
    z.tuple([
      z.nativeEnum(NUMERIC_OPERATORS).default(() => defaultValue),
      zodNumberNegativeConstraint,
    ]),
    z
      .tuple([zodNumberNegativeConstraint, zodNumberNegativeConstraint])
      .refine((tuple) => tuple[0] < tuple[1], {
        message: "The first number must be less than the second number",
      }),
  ])

  type TReturn = TOmitBetween extends true
    ? typeof tupleSchema
    : typeof unionSchema

  if (omitBetween) {
    return tupleSchema as TReturn
  }

  return unionSchema as TReturn
}

export const TEXT_OPERATORS = [
  "contains", // ILIKE
  "not.contains", // NOT ILIKE
  "equals", // aka "in", element-wise exact match
  "not.equals", // aka "not in", element-wise exact non-match
] as const

export const NUMERIC_OPERATORS = {
  GTE: "gte",
  GT: "gt",
  EQUALS: "equals",
  LT: "lt",
  LTE: "lte",
} as const

export const ALL_OPERATORS = [
  ...Object.values(NUMERIC_OPERATORS),
  ...TEXT_OPERATORS,
] as const

const zodTextOperatorEnum = z.enum(TEXT_OPERATORS)

export const textFilterSchema = z.tuple([
  zodTextOperatorEnum.default(
    () => "contains" as z.infer<typeof zodTextOperatorEnum>
  ),
  z.array(z.string()).nonempty(),
])

export const descriptionFilterSchema = z.tuple([
  z.enum(["contains", "not.contains"]).default(() => "contains" as const),
  z.array(z.string()).nonempty(),
])

export const TIME_FRAME_OPTIONS = ["days", "weeks", "months", "years"] as const
export const DATE_OPERATORS = ["before", "after", "equals"] as const

export const DATE_RANGE_OPTIONS = {
  THIS_WEEK: "This week",
  LAST_WEEK: "Last week",
  PAST_30_DAYS: "Past 30 days",
  PAST_90_DAYS: "Past 90 days",
  PAST_180_DAYS: "Past 180 days",
  PAST_360_DAYS: "Past 360 days",
} as const

export type DateRangeOption = Values<typeof DATE_RANGE_OPTIONS>

export const allDateRangeOptions =
  Object.values<DateRangeOption>(DATE_RANGE_OPTIONS)

export const zodDateSchema = z.custom<string>(isDateValid, {
  message: "Invalid date",
})

export const dateBeforeAfterSchema_DEPRECATED = z.tuple([
  z.enum(DATE_OPERATORS),
  zodDateSchema,
])

export const dateRangeSchema = z
  .tuple([z.string().nullable(), z.string().nullable()])
  .refine(
    ([start, end]) =>
      // This is meant to accept old format of ["after" | "before" | "equals", date], but without showing it in the filter
      // New way of using it is through the range tab, by leaving empty start or end for before or after, respectively
      (isOneOf(start, DATE_OPERATORS) && isDateValid(end)) ||
      isDateValid(start) ||
      isDateValid(end),
    {
      message: "Invalid date",
    }
  )
  // Then transform it to the new format
  .transform(([start, end]) => {
    if (isOneOf(start, DATE_OPERATORS)) {
      switch (start) {
        case "after":
          return [end, null]
        case "before":
          return [null, end]
        case "equals":
          return [end, end]
      }
    }
    return [start, end]
  })

export const dateRollingRangeSchema = z.tuple([
  z.number().int().positive(),
  z.enum(TIME_FRAME_OPTIONS),
  // Invert the time frame
  z.boolean().optional().default(false),
])

const dateDefaultSchema = z.nativeEnum(DATE_RANGE_OPTIONS)

export const dateFilterSchema = z.union([
  dateDefaultSchema,
  dateRangeSchema,
  dateRollingRangeSchema,
])

export const AND_OR_OPERATORS = ["OR", "AND"] as const
export const AND_OR_SCHEMA = z
  .enum(AND_OR_OPERATORS)
  .default(() => "OR" as const)

export type AndOrOperator = (typeof AND_OR_OPERATORS)[number]

export const andOrSchema = <T extends ZodTypeAny>(zodType: T) => {
  return z.union([
    z.tuple([AND_OR_SCHEMA, zodType]),
    z.tuple([AND_OR_SCHEMA, zodType]),
  ])
}

export const setFilterSchema = <Item extends ZodTypeAny = ZodString>(
  item?: Item
) => andOrSchema(z.array(item ?? z.string()))

export const booleanFilterSchema = z.boolean().nullable()

const HighlightsTitle = () => {
  const params = useParams()

  if (
    params.product === SpecterProducts.people ||
    params.product === SpecterProducts.talent
  ) {
    return <>People Highlights</>
  }

  return <>Highlights</>
}

const fieldTitleMap: Record<string, string | JSX.Element> = {
  id: "Specter - Signal ID",
  entityStatus: "Status",
  talent_id: "Specter - Talent Signal ID",
  talent_ids: "Specter - Talent Signal IDs",
  person_id: "Specter - Person ID",
  strategic_id: "Specter - Strategic Signal ID",
  stratetic_ids: "Specter - Strategic Signal IDs",
  first_name: "First Name",
  last_name: "Last Name",
  full_name: "Full Name",
  experience: "Experience",
  current_position_title: "Current Position Title",
  current_position_website: "Current Position Company Website",
  people_highlights: "People Highlights",
  AnnouncementDelayMonths: "Announcement Delay (Months)",
  AverageTenure: "Minimum Average Tenure",
  annualRevenueEstimate: "Annual Revenue Estimate (in USD)",
  BounceRate3MonthsGrowth: "Bounce Rate - 3 Months Growth",
  CategoryGroups: "Category Groups",
  Companies: "Companies - Direct Match",
  CompanyHQLocation: "Company HQ Location",
  CompanyLastFundingType: "Company Last Funding Type",
  CompanyName: "Company Name - Keywords",
  CrunchbaseURL: "Crunchbase - URL",
  CurrentTenure: "Time Spent at Current Role",
  Education: "School Name",
  EducationLocation: "School Location",
  Employees2MonthsGrowth: "Employees - 2 Months Growth (%)",
  Employees3MonthsGrowth: "Employees - 3 Months Growth (%)",
  Employees4MonthsGrowth: "Employees - 4 Months Growth (%)",
  Employees5MonthsGrowth: "Employees - 5 Months Growth (%)",
  Employees6MonthsGrowth: "Employees - 6 Months Growth (%)",
  Employees12MonthsGrowth: "Employees - 1 Year Growth (%)",
  Employees24MonthsGrowth: "Employees - 2 Year Growth (%)",
  EmployeesMonthlyGrowth: "Employees - Monthly Growth (%)",
  "composed_growth.employee_count": "Employees - Growth (%)",
  "growth_metrics.employee_count_1mo_ratio": "Employees - 1 Month Growth (%)",
  "growth_metrics.employee_count_3mo_ratio": "Employees - 3 Month Growth (%)",
  "growth_metrics.employee_count_6mo_ratio": "Employees - 6 Month Growth (%)",
  "growth_metrics.employee_count_12mo_ratio": "Employees - 1 Year Growth (%)",
  "growth_metrics.employee_count_24mo_ratio": "Employees - 2 Year Growth (%)",
  FacebookURL: "Facebook - URL",
  FoundedDate: "Founded Date",
  FundingRounds: "Funding Rounds",
  GooglePlay2MonthsReviewsGrowth: "Google Play - 2 Months Reviews Growth",
  GooglePlay3MonthsReviewsGrowth: "Google Play - 3 Months Reviews Growth",
  GooglePlay4MonthsReviewsGrowth: "Google Play - 4 Months Reviews Growth",
  GooglePlay5MonthsReviewsGrowth: "Google Play - 5 Months Reviews Growth",
  GooglePlay6MonthsReviewsGrowth: "Google Play - 6 Months Reviews Growth",
  GooglePlay12MonthsReviewsGrowth: "Google Play - 1 Year Reviews Growth",
  GooglePlay24MonthsReviewsGrowth: "Google Play - 2 Year Reviews Growth",
  "composed_growth.googleplay_reviews": "Google Play - Reviews Growth (%)",
  "growth_metrics.googleplay_reviews_1mo_ratio":
    "Google Play - 1 Month Reviews Growth (%)",
  "growth_metrics.googleplay_reviews_3mo_ratio":
    "Google Play - 3 Month Reviews Growth (%)",
  "growth_metrics.googleplay_reviews_6mo_ratio":
    "Google Play - 6 Month Reviews Growth (%)",
  GooglePlayAppID: "Google Play - App ID",
  GooglePlayInstalls: "Google Play - Installs",
  GooglePlayMonthlyReviewsGrowth: "Google Play - Monthly Reviews Growth",
  GooglePlayRating: "Google Play - Rating",
  GooglePlayReviews: "Google Play - Reviews",
  GooglePlayURL: "Google Play - URL",
  G2Rating: "G2 - Rating",
  G2TotalReviews: "G2 - Total Reviews",
  G2Medals: "G2 - Medals",
  G2ProductReviews: "G2 - Product Reviews",
  G2JSONData: "G2 - Data",
  HasCurrentPosition: "Has Current Active Position",
  HasNewHighlights: "New Signals",
  HasNewFundingHighlights: "New Funding Signals",
  HasNewGrowthHighlights: "New Growth Signals",
  HotCompanies: "Received Investor Interest",
  HotCompanies_string: "Received Investor Interest",
  HotSignals: "Hot Signals 🔥",
  HotSignals_string: "Hot Signals",
  HQLocation: "Location",
  HQRegion: "HQ Region",
  ITSpend: "IT Spend (in USD)",
  IndustryOG: "Industry",
  Instagram2MonthsFollowersGrowth: "Instagram - 2 Months Followers Growth",
  Instagram3MonthsFollowersGrowth: "Instagram - 3 Months Followers Growth",
  Instagram4MonthsFollowersGrowth: "Instagram - 4 Months Followers Growth",
  Instagram5MonthsFollowersGrowth: "Instagram - 5 Months Followers Growth",
  Instagram6MonthsFollowersGrowth: "Instagram - 6 Months Followers Growth",
  Instagram12MonthsFollowersGrowth: "Instagram - 1 Year Followers Growth",
  Instagram24MonthsFollowersGrowth: "Instagram - 2 Year Followers Growth",
  "composed_growth.instagram_followers": "Instagram - Followers Growth (%)",
  "composed_growth.instagram_followers_string":
    "Instagram - Followers Growth (%)",
  "growth_metrics.instagram_followers_1mo_ratio":
    "Instagram - 1 Month Followers Growth (%)",
  "growth_metrics.instagram_followers_3mo_ratio":
    "Instagram - 3 Month Followers Growth (%)",
  "growth_metrics.instagram_followers_6mo_ratio":
    "Instagram - 6 Month Followers Growth (%)",
  InstagramFollowers: "Instagram - Followers",
  InstagramFollowers_string: "Instagram - Followers",
  InstagramFollowing: "Instagram - Following",
  InstagramMonthlyFollowersGrowth: "Instagram - Monthly Followers Growth",
  InstagramURL: "Instagram - URL",
  InvestorHighlights: "Highlights",
  Highlights: <HighlightsTitle />,
  LastFundingAmount: "Last Funding Amount (in USD)",
  LinkedIn2MonthsFollowersGrowth: "LinkedIn - 2 Months Followers Growth",
  LinkedIn3MonthsFollowersGrowth: "LinkedIn - 3 Months Followers Growth",
  LinkedIn4MonthsFollowersGrowth: "LinkedIn - 4 Months Followers Growth",
  LinkedIn5MonthsFollowersGrowth: "LinkedIn - 5 Months Followers Growth",
  LinkedIn6MonthsFollowersGrowth: "LinkedIn - 6 Months Followers Growth",
  LinkedIn12MonthsFollowersGrowth: "LinkedIn - 1 Year Followers Growth",
  LinkedIn24MonthsFollowersGrowth: "LinkedIn - 2 Year Followers Growth",
  "composed_growth.linkedin_followers": "LinkedIn - Followers Growth (%)",
  "composed_growth.linkedin_followers_string":
    "LinkedIn - Followers Growth (%)",
  "growth_metrics.linkedin_followers_1mo_ratio":
    "LinkedIn - 1 Month Followers Growth (%)",
  "growth_metrics.linkedin_followers_3mo_ratio":
    "LinkedIn - 3 Month Followers Growth (%)",
  "growth_metrics.linkedin_followers_6mo_ratio":
    "LinkedIn - 6 Month Followers Growth (%)",
  LinkedIn: "LinkedIn",
  LinkedInFollowers: "LinkedIn - Followers",
  LinkedInFollowers_string: "LinkedIn - Followers",
  LinkedInMonthlyFollowersGrowth: "LinkedIn - Monthly Followers Growth",
  LinkedInURL: "LinkedIn - URL",
  LinkedInURLTalent: "LinkedIn - URL (Talent)",
  NewHighlights: "New Signals",
  NumberOfFounders: "Number of Founders",
  NumberOfFundingRounds: "Number of Funding Rounds",
  NumberOfInvestors: "Number of Investors",
  NumberOfPatents: "Number of Patents",
  NumberOfTechnologies: "Number of Technologies",
  NumberOfTrademarks: "Number of Trademarks",
  PagesPerVisit3MonthsGrowth: "Pages per Visit - 3 Months Growth",
  PagesPerVisit: "Pages per Visit",
  PastPosition: "Past Positions",
  Rank: "Specter Rank",
  SessionDuration3MonthsGrowth: "Session Duration (s) - 3 Months Growth",
  SessionDuration: "Session Duration (s)",
  SimilarWebsitesSimilarity: "Similar Websites and Similarity",
  SpecterPersonIDs: "Person",
  "StratintelMulti.numberOfSignals": "Number of Signals (Total)",
  "StratintelMulti.signalCountPast30d": "Number of Signals - Past 30 days",
  "StratintelMulti.signalCountPast90d": "Number of Signals - Past 90 days",
  "StratintelMulti.signalCountPast180d": "Number of Signals - Past 180 days",
  "StratintelMulti.signalCountPast360d": "Number of Signals - Past 360 days",
  "StratintelMulti.signalCountPast720d": "Number of Signals - Past 720 days",
  "StratintelMulti.allSignalIds": "All Signals",
  SubIndustry: "Sub-Industry",
  TalentTags: "Tags",
  TotalAppDownloads2MonthsGrowth: "Total App Downloads - 2 Months Growth (%)",
  TotalAppDownloads3MonthsGrowth: "Total App Downloads - 3 Months Growth (%)",
  TotalAppDownloads4MonthsGrowth: "Total App Downloads - 4 Months Growth (%)",
  TotalAppDownloads5MonthsGrowth: "Total App Downloads - 5 Months Growth (%)",
  TotalAppDownloads6MonthsGrowth: "Total App Downloads - 6 Months Growth (%)",
  TotalAppDownloads: "App Downloads",
  TotalAppDownloadsMonthlyGrowth: "Total App Downloads - Monthly Growth (%)",
  "composed_growth.total_app_downloads": "App Downloads - Growth (%)",
  "growth_metrics.app_downloads_1mo_ratio":
    "Total App Downloads - 1 Month Growth (%)",
  "growth_metrics.app_downloads_3mo_ratio":
    "Total App Downloads - 3 Month Growth (%)",
  "growth_metrics.app_downloads_6mo_ratio":
    "Total App Downloads - 6 Month Growth (%)",
  TotalFundingAmount: "Total Funding Amount (in USD)",
  TrustpilotData: "Trustpilot - Rating",
  TrustpilotReviews: "Trustpilot - Reviews",
  TrustpilotTotalReviews: "Trustpilot - Total Reviews",
  TrustpilotRating: "Trustpilot - Rating",
  TrustpilotJSONData: "Trustpilot - Data",
  Twitter2MonthsFollowersGrowth: "Twitter - 2 Months Followers Growth",
  Twitter3MonthsFollowersGrowth: "Twitter - 3 Months Followers Growth",
  Twitter4MonthsFollowersGrowth: "Twitter - 4 Months Followers Growth",
  Twitter5MonthsFollowersGrowth: "Twitter - 5 Months Followers Growth",
  Twitter6MonthsFollowersGrowth: "Twitter - 6 Months Followers Growth",
  Twitter12MonthsFollowersGrowth: "Twitter - 12 Months Followers Growth",
  Twitter24MonthsFollowersGrowth: "Twitter - 24 Months Followers Growth",
  "composed_growth.twitter_followers": "Twitter - Followers Growth (%)",
  "composed_growth.twitter_followers_string": "Twitter - Followers Growth (%)",
  "growth_metrics.twitter_followers_1mo_ratio":
    "Twitter - 1 Month Followers Growth (%)",
  "growth_metrics.twitter_followers_3mo_ratio":
    "Twitter - 3 Month Followers Growth (%)",
  "growth_metrics.twitter_followers_6mo_ratio":
    "Twitter - 6 Month Followers Growth (%)",
  TwitterFFRatio: "Twitter - F/F Ratio",
  TwitterFollowers: "Twitter - Followers",
  TwitterFollowers_string: "Twitter - Followers",
  TwitterMonthlyFollowersGrowth: "Twitter - Monthly Followers Growth",
  TwitterURL: "Twitter - URL",
  TwitterURLTalent: "Twitter - URL (Talent)",
  WebVisits2MonthsGrowth: "Web Visits - 2 Months Growth (%)",
  WebVisits3MonthsGrowth: "Web Visits - 3 Months Growth (%)",
  WebVisits4MonthsGrowth: "Web Visits - 4 Months Growth (%)",
  WebVisits5MonthsGrowth: "Web Visits - 5 Months Growth (%)",
  WebVisits6MonthsGrowth: "Web Visits - 6 Months Growth (%)",
  WebVisits12MonthsGrowth: "Web Visits - 12 Months Growth (%)",
  WebVisits24MonthsGrowth: "Web Visits - 24 Months Growth (%)",
  WebVisitsMonthlyGrowth: "Web Visits - Monthly Growth (%)",
  "composed_growth.web_visits": "Web Visits - Growth (%)",
  "growth_metrics.web_visits_1mo_ratio": "Web Visits - 1 Month Growth (%)",
  "growth_metrics.web_visits_3mo_ratio": "Web Visits - 3 Month Growth (%)",
  "growth_metrics.web_visits_6mo_ratio": "Web Visits - 6 Month Growth (%)",
  "growth_metrics.web_visits_12mo_ratio": "Web Visits - 1 Year Growth (%)",
  "growth_metrics.web_visits_24mo_ratio": "Web Visits - 2 Year Growth (%)",
  WebsitePopularityRank: "Website Rank",
  WebsitePopularityRank2MonthsGrowth:
    "Website Popularity Rank - 2 Months Growth",
  WebsitePopularityRank3MonthsGrowth:
    "Website Popularity Rank - 3 Months Growth",
  WebsitePopularityRank4MonthsGrowth:
    "Website Popularity Rank - 4 Months Growth",
  WebsitePopularityRank5MonthsGrowth:
    "Website Popularity Rank - 5 Months Growth",
  WebsitePopularityRank6MonthsGrowth:
    "Website Popularity Rank - 6 Months Growth",
  WebsitePopularityRank12MonthsGrowth:
    "Website Popularity Rank - 1 Year Growth",
  WebsitePopularityRank24MonthsGrowth:
    "Website Popularity Rank - 2 Year Growth",
  WebsitePopularityRankMonthlyGrowth:
    "Website Popularity Rank - Monthly Growth",
  "composed_growth.popularity_rank.diff": "Website Rank - Growth",
  "growth_metrics.popularity_rank_1mo_diff": "Website Rank - 1 Month Growth",
  "growth_metrics.popularity_rank_3mo_diff": "Website Rank - 3 Month Growth",
  "growth_metrics.popularity_rank_6mo_diff": "Website Rank - 6 Month Growth",
  acquiredBy: "Acquired By",
  acquiredDate: "Acquired Date",
  acquiredName: "Acquiree Name",
  acquiredOn: "Acquisition Date",
  acquired: "Acquiree Name",
  acquirer: "Acquirer Name",
  acquisitionPrice: "Acquisition Price (in USD)",
  companySize: "Company Size",
  contactEmail: "Contact Email",
  crunchbaseUrl: "Crunchbase - URL",
  description: "Description",
  domain: "Domain",
  employeeCount: "Employee Count",
  employeeMonthlyGrowth1: "Employees - Monthly Growth (%)",
  employeeMonthlyGrowth2: "Employees - 2 Months Growth (%)",
  employeeMonthlyGrowth3: "Employees - 3 Months Growth (%)",
  employeeMonthlyGrowth4: "Employees - 4 Months Growth (%)",
  employeeMonthlyGrowth5: "Employees - 5 Months Growth (%)",
  employeeMonthlyGrowth6: "Employees - 6 Months Growth (%)",
  employeeMonthlyGrowth12: "Employees - 1 Year Growth (%)",
  employeeMonthlyGrowth24: "Employees - 2 Year Growth (%)",
  roles: "Roles",
  facebookUrl: "Facebook - URL",
  foundedYear: "Founded Date",
  founders: "Founders",
  foundersCount: "Number of Founders",
  fundingRounds: "Funding Rounds",
  fundingRoundsCount: "Number of Funding Rounds",
  funds: "Fund Details",
  g2_data: "G2 - Products",
  g2_rating_avg: "G2 - Rating",
  g2_total_reviews: "G2 - Total Reviews",
  githubUrl: "Github - URL",
  googlePlayAppId: "Google Play App ID",
  googlePlayInstalls: "Google Play - Installs",
  googlePlayRating: "Google Play - Rating",
  googlePlayReviews: "Google Play - Reviews",
  googlePlayReviewsMonthlyGrowth1: "Google Play - Monthly Reviews Growth",
  googlePlayReviewsMonthlyGrowth2: "Google Play - 2 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth3: "Google Play - 3 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth4: "Google Play - 4 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth5: "Google Play - 5 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth6: "Google Play - 6 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth12: "Google Play - 1 Year Reviews Growth",
  googlePlayReviewsMonthlyGrowth24: "Google Play - 2 Year Reviews Growth",
  googlePlayUrl: "Google Play - URL",
  growthStage: "Growth Stage",
  hqLocation: "HQ Location",
  hqRegion: "HQ Region",
  iTunes2MonthsReviewsGrowth: "iTunes - 2 Months Reviews Growth",
  iTunes3MonthsReviewsGrowth: "iTunes - 3 Months Reviews Growth",
  iTunes4MonthsReviewsGrowth: "iTunes - 4 Months Reviews Growth",
  iTunes5MonthsReviewsGrowth: "iTunes - 5 Months Reviews Growth",
  iTunes6MonthsReviewsGrowth: "iTunes - 6 Months Reviews Growth",
  iTunes12MonthsReviewsGrowth: "iTunes - 1 Year Reviews Growth",
  iTunes24MonthsReviewsGrowth: "iTunes - 2 Year Reviews Growth",
  "composed_growth.itunes_reviews": "iTunes - Reviews Growth (%)",
  "growth_metrics.itunes_reviews_1mo_ratio":
    "iTunes - 1 Month Reviews Growth (%)",
  "growth_metrics.itunes_reviews_3mo_ratio":
    "iTunes - 3 Month Reviews Growth (%)",
  "growth_metrics.itunes_reviews_6mo_ratio":
    "iTunes - 6 Month Reviews Growth (%)",
  iTunes: "iTunes",
  iTunesAppID: "iTunes - App ID",
  iTunesMonthlyReviewsGrowth: "iTunes - Monthly Reviews Growth",
  iTunesRating: "iTunes - Rating",
  iTunesReviews: "iTunes - Reviews",
  iTunesURL: "iTunes - URL",
  industry: "Industry",
  instagramFollowers: "Instagram - Followers",
  instagramFollowersMonthlyGrowth1: "Instagram - Monthly Followers Growth",
  instagramFollowersMonthlyGrowth2: "Instagram - 2 Months Followers Growth",
  instagramFollowersMonthlyGrowth3: "Instagram - 3 Months Followers Growth",
  instagramFollowersMonthlyGrowth4: "Instagram - 4 Months Followers Growth",
  instagramFollowersMonthlyGrowth5: "Instagram - 5 Months Followers Growth",
  instagramFollowersMonthlyGrowth6: "Instagram - 6 Months Followers Growth",
  instagramFollowersMonthlyGrowth12: "Instagram - 1 Year Followers Growth",
  instagramFollowersMonthlyGrowth24: "Instagram - 2 Year Followers Growth",
  instagramFollowing: "Instagram - Following",
  instagramUrl: "Instagram - URL",
  investors: "Investors",
  investorCount: "Number of Investors",
  investorsCount: "Number of Investors",
  ipoDetails: "IPO Details",
  itSpend: "IT Spend (in USD)",
  itunesAppId: "iTunes App ID",
  itunesRating: "iTunes - Rating",
  itunesReviews: "iTunes - Reviews",
  itunesReviewsMonthlyGrowth1: "iTunes - Monthly Reviews Growth",
  itunesReviewsMonthlyGrowth2: "iTunes - 2 Months Reviews Growth",
  itunesReviewsMonthlyGrowth3: "iTunes - 3 Months Reviews Growth",
  itunesReviewsMonthlyGrowth4: "iTunes - 4 Months Reviews Growth",
  itunesReviewsMonthlyGrowth5: "iTunes - 5 Months Reviews Growth",
  itunesReviewsMonthlyGrowth6: "iTunes - 6 Months Reviews Growth",
  itunesReviewsMonthlyGrowth12: "iTunes - 1 Year Reviews Growth",
  itunesReviewsMonthlyGrowth24: "iTunes - 2 Year Reviews Growth",
  itunesUrl: "iTunes - URL",
  lastFundingAmount: "Last Funding Amount (in USD)",
  lastFundingDate: "Last Funding Date",
  lastFundingType: "Last Funding Type",
  linkedinFollowers: "LinkedIn - Followers",
  linkedinFollowersMonthlyGrowth1: "LinkedIn - Monthly Followers Growth",
  linkedinFollowersMonthlyGrowth2: "LinkedIn - 2 Months Followers Growth",
  linkedinFollowersMonthlyGrowth3: "LinkedIn - 3 Months Followers Growth",
  linkedinFollowersMonthlyGrowth4: "LinkedIn - 4 Months Followers Growth",
  linkedinFollowersMonthlyGrowth5: "LinkedIn - 5 Months Followers Growth",
  linkedinFollowersMonthlyGrowth6: "LinkedIn - 6 Months Followers Growth",
  linkedinFollowersMonthlyGrowth12: "LinkedIn - 1 Year Followers Growth",
  linkedinFollowersMonthlyGrowth24: "LinkedIn - 2 Year Followers Growth",
  linkedinUrl: "LinkedIn - URL",
  moneyRaised: "Money Raised (in USD)",
  raisedAmount: "Money Raised (in USD)",
  month: "Month",
  companyName: "Company Name",
  companyNameAliases: "Company Name Aliases",
  new: "New Companies",
  news: "News",
  nInvestments: "Number of Investments",
  nLeadInvestments: "Number of Lead Investments",
  nExits: "Number of Exits",
  nFunds: "Number of Funds",
  operatingStatus: "Operating Status",
  otherDomains: "Other Domains",
  patentsCount: "Number of Patents",
  phoneNumber: "Phone Number",
  postMoneyValuation: "Post Money Valuation (in USD)",
  preMoneyValuation: "Pre Money Valuation (in USD)",
  primaryRole: "Primary Role",
  rank: "Rank",
  seniority: "Level of Seniority",
  sharePrice: "Share Price (in USD)",
  socialTrafficBreakdown: "Social Traffic Breakdown",
  specterId: "Specter - ID",
  specter_id: "Specter - Organization ID",
  specter_url: "Specter - URL",
  specter_source: "Source - CRM",
  status: "Operating Status",
  stockExchangeSymbol: "Stock Exchange",
  subIndustry: "Sub-industry",
  tags: "Tags",
  technologies: "Active Technologies",
  technologiesCount: "Number of Technologies",
  totalAppDownloads: "Total App Downloads",
  totalAppDownloadsMonthlyGrowth1: "Total App Downloads - Monthly Growth (%)",
  totalAppDownloadsMonthlyGrowth2: "Total App Downloads - 2 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth3: "Total App Downloads - 3 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth4: "Total App Downloads - 4 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth5: "Total App Downloads - 5 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth6: "Total App Downloads - 6 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth12: "Total App Downloads - 1 Year Growth (%)",
  totalAppDownloadsMonthlyGrowth24: "Total App Downloads - 2 Year Growth (%)",
  totalFundingAmount: "Total Funding Amount (in USD)",
  trademarksCount: "Number of Trademarks",
  trustpilot_data: "Trustpilot - Rating",
  "trustpilot_data.review_count": "Trustpilot - Total Reviews",
  twitterFollowers: "Twitter - Followers",
  twitterFollowersMonthlyGrowth1: "Twitter - Monthly Followers Growth",
  twitterFollowersMonthlyGrowth2: "Twitter - 2 Months Followers Growth",
  twitterFollowersMonthlyGrowth3: "Twitter - 3 Months Followers Growth",
  twitterFollowersMonthlyGrowth4: "Twitter - 4 Months Followers Growth",
  twitterFollowersMonthlyGrowth5: "Twitter - 5 Months Followers Growth",
  twitterFollowersMonthlyGrowth6: "Twitter - 6 Months Followers Growth",
  twitterFollowersMonthlyGrowth12: "Twitter - 1 Year Followers Growth",
  twitterFollowersMonthlyGrowth24: "Twitter - 2 Year Followers Growth",
  twitterUrl: "Twitter - URL",
  valuationPrice: "Valuation Price (in USD)",
  webBounceRate: "Bounce Rate",
  webBounceRateMonthlyGrowth3: "Bounce Rate - 3 Months Growth",
  webCountryBreakdown: "Country Breakdown",
  webOrganicSearchPercentage: "Organic Search Percentage",
  webPagesPerVisit: "Pages per Visit",
  webPagesPerVisitMonthlyGrowth3: "Pages per Visit - 3 Months Growth",
  webPaidSearchPercentage: "Paid Search Percentage",
  webPopularityRank: "Website Popularity Rank",
  webPopularityRankMonthlyGrowth1: "Website Popularity Rank - Monthly Growth",
  webPopularityRankMonthlyGrowth2: "Website Popularity Rank - 2 Months Growth",
  webPopularityRankMonthlyGrowth3: "Website Popularity Rank - 3 Months Growth",
  webPopularityRankMonthlyGrowth4: "Website Popularity Rank - 4 Months Growth",
  webPopularityRankMonthlyGrowth5: "Website Popularity Rank - 5 Months Growth",
  webPopularityRankMonthlyGrowth6: "Website Popularity Rank - 6 Months Growth",
  webPopularityRankMonthlyGrowth12: "Website Popularity Rank - 1 Year Growth",
  webPopularityRankMonthlyGrowth24: "Website Popularity Rank - 2 Year Growth",
  webSessionDuration: "Session Duration (s)",
  webSessionDurationMonthlyGrowth3: "Session Duration (s) - 3 Months Growth",
  webSimilarWebsitesSimilarity: "Similar Websites and Similarity",
  webTopCountry: "Top Country",
  webTrafficSources: "Traffic Sources",
  webVisits: "Web Visits",
  webVisitsMonthlyGrowth1: "Web Visits - Monthly Growth (%)",
  webVisitsMonthlyGrowth2: "Web Visits - 2 Months Growth (%)",
  webVisitsMonthlyGrowth3: "Web Visits - 3 Months Growth (%)",
  webVisitsMonthlyGrowth4: "Web Visits - 4 Months Growth (%)",
  webVisitsMonthlyGrowth5: "Web Visits - 5 Months Growth (%)",
  webVisitsMonthlyGrowth6: "Web Visits - 6 Months Growth (%)",
  webVisitsMonthlyGrowth12: "Web Visits - 1 Year Growth (%)",
  webVisitsMonthlyGrowth24: "Web Visits - 2 Year Growth (%)",
  website: "Website",
  wentPublicOn: "IPO Date",
  Description: "Text Search",
  strategic_description: "Description",
  LastActivityDate: "Last Activity Date",
  inCRM: "In My CRM",
  "My CRM": "My CRM",
  types: "Investor Type",
  stages: "Investment Stage",
  listId: "Companies In List",
  b2x: "Customer Focus",
  tcp: (
    <Text as="span">
      Typical Customer Profile <AITag size="10px" />
    </Text>
  ),
  tcp_string: "Typical Customer Profile",
  traction: (
    <Text as="span">
      Reported Traction Highlights <AITag size="10px" />
    </Text>
  ),
  traction_string: "Reported Traction Highlights",
  certifications_mapped: "Certifications",
  semanticSearch: (
    <Text as="span">
      Semantic Search <AITag size="10px" />
    </Text>
  ),
  semantic_search_string: "Semantic Search",
  clients_mapped: (
    <Text as="span">
      Reported Clients <AITag size="10px" />
    </Text>
  ),
  clients_mapped_string: "Reported Clients",
  similarToCompanies: (
    <Text as="span">
      Similar Companies <AITag size="10px" />
    </Text>
  ),

  CompanyTotalFundingAmount: "Company Total Funding Amount (in USD)",
  CompanyLastFundingAmount: "Company Last Funding Amount (in USD)",
}

export const GROWTH_PERIODS = ["1mo", "3mo", "6mo", "12mo", "24mo"] as const
export type GrowthPeriod = (typeof GROWTH_PERIODS)[number]
export const GROWTH_PERIODS_LABELS = {
  "1mo": "Month",
  "3mo": "3 Months",
  "6mo": "6 Months",
  "12mo": "Year",
  "24mo": "2 Years",
}
export const GROWTH_PERIODS_SHORT_LABELS = {
  "1mo": "1mo",
  "3mo": "3mo",
  "6mo": "6mo",
  "12mo": "1y",
  "24mo": "2y",
}

export const GROWTH_PERIODS_SCHEMA = z
  .enum(GROWTH_PERIODS)
  .default(() => "1mo" as const)

const growthTupleSchema = (props: NumericFilterProps = {}) =>
  z.tuple([
    GROWTH_PERIODS_SCHEMA,
    numericFilterRevampedSchema({ allowNegatives: true, ...props }),
  ])

export const growthFilterSchema = (
  periods: readonly GrowthPeriod[] = GROWTH_PERIODS,
  props: NumericFilterProps = {}
) => {
  const growthTupleSchemaInstance = growthTupleSchema(props)
  type TupleSchema = typeof growthTupleSchemaInstance
  type TupleSchemaArray = [TupleSchema, TupleSchema, ...TupleSchema[]]

  return z.union(
    new Array(periods.length).fill(
      growthTupleSchemaInstance
    ) as TupleSchemaArray
  )
}

export type GrowthFilter = z.infer<ReturnType<typeof growthFilterSchema>>

/**
 * Returns a renderable version of the title
 */
export const getSignalFieldTitle = (field: string): JSX.Element | string => {
  let title = fieldTitleMap[field]

  if (title) {
    return title
  }

  return titleCase(sentenceCase(field))
}

/**
 * Export an explicit string version of the title
 */
export const getSignalFieldTitleString = (field: string): string => {
  if (fieldTitleMap[`${field}_string`]) {
    return fieldTitleMap[`${field}_string`] as string
  }

  if (fieldTitleMap[field]) {
    return fieldTitleMap[field] as string
  }

  return titleCase(sentenceCase(field))
}

/**
 * Given a set of operators, put 'equals' and 'not.equals' first
 * and then include the rest
 */
export function equalsOperatorsFirst(operators: string[]): string[] {
  const output: string[] = []
  const copy: string[] = [...operators]

  const equalIndex = operators.indexOf("equals")
  const notEqualIndex = operators.indexOf("not.equals")

  if (!isNullish(equalIndex)) output.push(operators[equalIndex])
  if (!isNullish(notEqualIndex)) output.push(operators[notEqualIndex])

  return [
    ...output,
    ...copy.filter((elem) => !["equals", "not.equals"].includes(elem)),
  ]
}

export const SemanticRelevanceEnum = {
  strict: "strict",
  standard: "standard",
  broad: "broad",
} as const

export const OperatingStatusEnum = {
  active: "active",
  acquired: "acquired",
  closed: "closed",
  ipo: "ipo",
} as const

export type OperatingStatus =
  (typeof OperatingStatusEnum)[keyof typeof OperatingStatusEnum]

export const OPERATING_STATUS_LABEL = {
  [OperatingStatusEnum.active]: "Active",
  [OperatingStatusEnum.acquired]: "Acquired",
  [OperatingStatusEnum.closed]: "Closed",
  [OperatingStatusEnum.ipo]: "IPO",
} as const

export const companyOperatorsFilterSchema = <
  InnerSchema extends ZodTypeAny,
  Operators extends readonly [string, ...string[]]
>({
  innerSchema,
  operators,
}: {
  innerSchema: InnerSchema
  operators: Operators
}) => {
  return z.tuple([z.enum(operators), innerSchema])
}

export const getInnerSchemaAndOptions = <
  Field extends keyof typeof COMPANY_OPERATOR_BY_FILTER
>(
  field: Field
) => {
  const innerSchema = COMPANY_OPERATOR_BY_FILTER[field]
    .innerSchema as (typeof COMPANY_OPERATOR_BY_FILTER)[Field]["innerSchema"]

  const options = COMPANY_OPERATOR_BY_FILTER[field]
    .options as (typeof COMPANY_OPERATOR_BY_FILTER)[Field]["options"]

  const operators = TypedObjectKeysTuple(options)

  return { innerSchema, operators } as const
}

export type CompanyOperatorsFilter<
  InnerSchema extends ZodTypeAny = ZodAny,
  Operators extends readonly [string, ...string[]] = typeof COMPANY_OPERATORS
> = z.infer<
  ReturnType<typeof companyOperatorsFilterSchema<InnerSchema, Operators>>
>

export const COMPANY_OPERATOR_BY_FILTER: Record<
  CompanyOperatorsField,
  {
    innerSchema: ZodTypeAny
    options: Partial<Record<CompanyOperator, string>>
    defaultOperator?: CompanyOperator
  }
> = {
  Department: {
    innerSchema: setFilterSchema(z.enum(PEOPLE_DEPARTMENTS)),
    options: {
      Current: "Find people who currently work in these departments.",
      All: "Find people who work/worked in these departments at any point in their career.",
    },
  },
  JobTitles: {
    innerSchema: z.array(z.string()),
    options: {
      Current: "Find people who currently hold these job titles.",
      CurrentRecent:
        "Find people who currently hold/held these job titles within the last 2 years.",
      All: "Find people who currently hold/held these job titles at any point in their career.",
    },
  },
  PastJobTitles: {
    innerSchema: z.array(z.string()),
    options: {
      Past: "Find people who held these job titles at any point in their career.",
    },
  },
  Companies: {
    innerSchema: setFilterSchema(),
    options: {
      All: "Find people who currently work at or have worked at the selected companies.",
      Current: "Find people who currently work at the selected companies.",
      Past: "Find people who worked at the selected companies in the past but no longer work there.",
      // TODO: Q1
      // FundingStage: "Find people who worked at the selected companies when they were in the selected funding stages."
    },
  },
  CompanyIndustries: {
    innerSchema: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    options: {
      All: "Find people who currently work at or have worked in the selected industries.",
      Current: "Find people who currently work in the selected industries.",
    },
  },
  CompanyInvestors: {
    innerSchema: setFilterSchema(),
    options: {
      All: "Experience at companies currently backed by the selected investors.",
      Current:
        "Currently working at companies currently backed by the selected investors.",
      Past: "Previously worked at companies backed by the selected investors.",
    },
  },
  CompanySize: {
    innerSchema: z.array(z.nativeEnum(COMPANY_SIZE_OPTIONS)),
    options: {
      All: "Find people who currently work at or have worked at companies of the selected size.",
      Current:
        "Find people who currently work at companies of the selected size.",
      Past: "Find people who worked at companies of the selected size in the past but no longer work there.",
    },
  },
  CompanyHQLocation: {
    innerSchema: z.array(z.string()),
    options: {
      All: "Find people who currently work at or have worked at companies in the selected locations.",
      Current:
        "Find people who currently work at companies in the selected locations.",
      Past: "Find people who worked at companies in the selected locations in the past but no longer work there.",
    },
  },
  CompanyGrowthStage: {
    innerSchema: z.array(z.enum(GROWTH_STAGE_OPTIONS)),
    options: {
      All: "Find people who currently work at or have worked at companies in the selected growth stages.",
      Current:
        "Find people who currently work at companies in the selected growth stages.",
      Past: "Find people who worked at companies in the selected growth stages in the past but no longer work there.",
    },
  },
  CompanyLastFundingAmount: {
    innerSchema: numericFilterRevampedSchema(),
    options: {
      All: "Find people who currently work at or have worked at companies with the selected last funding amount.",
      Current:
        "Find people who currently work at companies with the selected last funding amount.",
      Past: "Find people who worked at companies with the selected last funding amount in the past but no longer work there.",
    },
  },
  CompanyTotalFundingAmount: {
    innerSchema: numericFilterRevampedSchema(),
    options: {
      All: "Find people who currently work at or have worked at companies with the selected total funding amount.",
      Current:
        "Find people who currently work at companies with the selected total funding amount.",
      Past: "Find people who worked at companies with the selected total funding amount in the past but no longer work there.",
    },
  },
  CompanyFundingType: {
    innerSchema: z.array(z.enum(FUNDING_TYPE_OPTIONS)),
    options: {
      All: "Find people who currently work at or have worked at companies with the selected funding type.",
      Current:
        "Find people who currently work at companies with the selected funding type.",
      Past: "Find people who worked at companies with the selected funding type in the past but no longer work there.",
    },
  },
  CompanyName: {
    innerSchema: z.array(z.string()),
    options: {
      All: "Find people who currently work at or have worked at companies with the selected name.",
      Current:
        "Find people who currently work at companies with the selected name.",
      CurrentRecent:
        "Find people who currently work/have worked within the last 2 years at companies with the selected name.",
    },
  },
  CompanyFoundedDate: {
    innerSchema: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS.GTE,
      extraConstraints: (z) => z.lte(new Date().getFullYear()),
    }),
    options: {
      Current:
        "Find people who currently work at companies founded in the selected year.",
    },
  },
}

export const currentTenureFilterSchema = z
  .tuple([
    z.enum(CURRENT_TENURE_OPTIONS).nullable(),
    z.enum(CURRENT_TENURE_OPTIONS).nullable(),
  ])
  .refine(([min, max]) => {
    // If they're both defined, the minimum must be less than or equal to the maximum
    if (min && max) {
      return (
        CURRENT_TENURE_OPTIONS.indexOf(min) <=
        CURRENT_TENURE_OPTIONS.indexOf(max)
      )
    }

    // If not, at least one should be
    return Boolean(min) || Boolean(max)
  }, "Minimum value must be less than or equal to maximum value")

export type CurrentTenureFilter = z.infer<typeof currentTenureFilterSchema>

export const languagesFilterSchema = z.tuple([
  z.enum(LANGUAGE_PROFICIENCY_OPTIONS),
  setFilterSchema(),
])

export type LanguagesFilter = z.infer<typeof languagesFilterSchema>
