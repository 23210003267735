import { SpecterProducts } from "@prisma/client"
import { z } from "zod"
import { SearchesAndListsProducts } from "~/components"

export const sortValues = z.enum(["asc", "desc"])

// ====== Company Signals ====== //

export const companySignalSortValidation = z
  .object({
    companyName: sortValues,
    rank: sortValues,
    foundedYear: sortValues,
    totalFundingAmount: sortValues,
    lastFundingAmount: sortValues,
    lastFundingDate: sortValues,
    lastFundingType: sortValues,
    postMoneyValuation: sortValues,
    fundingRoundsCount: sortValues,
    investorsCount: sortValues,
    companySize: sortValues,
    foundersCount: sortValues,
    webVisits: sortValues,
    "growth_metrics.web_visits_1mo_ratio": sortValues,
    "growth_metrics.web_visits_3mo_ratio": sortValues,
    "growth_metrics.web_visits_6mo_ratio": sortValues,
    "growth_metrics.web_visits_12mo_ratio": sortValues,
    "growth_metrics.web_visits_24mo_ratio": sortValues,
    webBounceRate: sortValues,
    webBounceRateMonthlyGrowth3: sortValues,
    webSessionDuration: sortValues,
    webSessionDurationMonthlyGrowth3: sortValues,
    webPopularityRank: sortValues,
    "growth_metrics.popularity_rank_1mo_diff": sortValues,
    "growth_metrics.popularity_rank_3mo_diff": sortValues,
    "growth_metrics.popularity_rank_6mo_diff": sortValues,
    employeeCount: sortValues,
    "growth_metrics.employee_count_1mo_ratio": sortValues,
    "growth_metrics.employee_count_3mo_ratio": sortValues,
    "growth_metrics.employee_count_6mo_ratio": sortValues,
    "growth_metrics.employee_count_12mo_ratio": sortValues,
    "growth_metrics.employee_count_24mo_ratio": sortValues,
    linkedinFollowers: sortValues,
    "growth_metrics.linkedin_followers_1mo_ratio": sortValues,
    "growth_metrics.linkedin_followers_3mo_ratio": sortValues,
    "growth_metrics.linkedin_followers_6mo_ratio": sortValues,
    twitterFollowers: sortValues,
    "growth_metrics.twitter_followers_1mo_ratio": sortValues,
    "growth_metrics.twitter_followers_3mo_ratio": sortValues,
    "growth_metrics.twitter_followers_6mo_ratio": sortValues,
    instagramFollowers: sortValues,
    "growth_metrics.instagram_followers_1mo_ratio": sortValues,
    "growth_metrics.instagram_followers_3mo_ratio": sortValues,
    "growth_metrics.instagram_followers_6mo_ratio": sortValues,
    instagramFollowing: sortValues,
    totalAppDownloads: sortValues,
    "growth_metrics.app_downloads_1mo_ratio": sortValues,
    "growth_metrics.app_downloads_3mo_ratio": sortValues,
    "growth_metrics.app_downloads_6mo_ratio": sortValues,
    itunesRating: sortValues,
    itunesReviews: sortValues,
    "growth_metrics.itunes_reviews_1mo_ratio": sortValues,
    "growth_metrics.itunes_reviews_3mo_ratio": sortValues,
    "growth_metrics.itunes_reviews_6mo_ratio": sortValues,
    googlePlayRating: sortValues,
    googlePlayReviews: sortValues,
    "growth_metrics.googleplay_reviews_1mo_ratio": sortValues,
    "growth_metrics.googleplay_reviews_3mo_ratio": sortValues,
    "growth_metrics.googleplay_reviews_6mo_ratio": sortValues,
    googlePlayInstalls: sortValues,
    awardsCount: sortValues,
    g2_rating_avg: sortValues,
    g2_total_reviews: sortValues,
    trustpilot_data: sortValues,
    "trustpilot_data.review_count": sortValues,
    semanticScore: sortValues,
  })
  .partial()

export function renameKeys<T extends object>(
  obj: T,
  mapKey: (key: keyof T) => string
): { [key: string]: any } {
  const keyValues = Object.entries(obj).map(([key, value]) => {
    const newKey = mapKey(key as keyof T)
    return { [newKey]: value }
  })
  return Object.assign({}, ...keyValues)
}

export type CompanySignalSort = z.infer<typeof companySignalSortValidation>

export const companySignalSortFields = Object.keys(
  companySignalSortValidation.shape
) as (keyof CompanySignalSort)[]

// ====== Talent Signals ====== //

export const talentSignalSortValidation = z
  .object({
    SignalDate: sortValues,
    SignalScore: sortValues,
    // FoundedDate: sortValues,
    // YearsOfExperience: sortValues,
    // TwitterFollowers: sortValues,
  })
  .partial()

export type TalentSignalSort = z.infer<typeof talentSignalSortValidation>

export const talentSignalSortFields = Object.keys(
  talentSignalSortValidation.shape
) as (keyof TalentSignalSort)[]

// ====== Strategic Signals ====== //

export const strategicSignalSortValidation = z
  .object({
    SignalDate: sortValues,
    SignalScore: sortValues,
    Founded: sortValues,
    TotalFundingAmount: sortValues,
    LastFundingDate: sortValues,
    LastFundingAmount: sortValues,
    "StratintelMulti.numberOfSignals": sortValues,
    "StratintelMulti.signalCountPast30d": sortValues,
    "StratintelMulti.signalCountPast90d": sortValues,
    "StratintelMulti.signalCountPast180d": sortValues,
    "StratintelMulti.signalCountPast360d": sortValues,
    "StratintelMulti.signalCountPast720d": sortValues,
  })
  .partial()

export type StrategicSignalSort = z.infer<typeof strategicSignalSortValidation>

export const strategicSignalSortFields = Object.keys(
  strategicSignalSortValidation.shape
) as (keyof StrategicSignalSort)[]

// ====== Investors ====== //

export const investorsDBSortValidation = z
  .object({
    rank: sortValues,
    foundedYear: sortValues,
    nInvestments: sortValues,
    nLeadInvestments: sortValues,
    nExits: sortValues,
    nFunds: sortValues,
  })
  .partial()

export type InvestorsDBSort = z.infer<typeof investorsDBSortValidation>

// ====== Funding Rounds ====== //

export const fundingRoundsDBSortValidation = z
  .object({
    raisedAmount: sortValues,
    postMoneyValuation: sortValues,
    announcedOn: sortValues,
  })
  .partial()

export type FundingRoundsDBSort = z.infer<typeof fundingRoundsDBSortValidation>

// ====== Acquisitions ====== //

export const acquisitionDBSortValidation = z
  .object({
    acquisitionPrice: sortValues,
    acquisitionType: sortValues,
  })
  .partial()

export type AcquisitionDBSort = z.infer<typeof acquisitionDBSortValidation>

// ====== IPO ====== //

export const ipoDBSortValidation = z
  .object({
    sharePrice: sortValues,
    valuationPrice: sortValues,
    moneyRaised: sortValues,
  })
  .partial()

export type IpoDBSort = z.infer<typeof ipoDBSortValidation>

// ====== People ====== //

export const peopleDBSortValidation = z
  .object({
    // TODO: add people sortable fields
  })
  .partial()

export type PeopleDBSort = z.infer<typeof peopleDBSortValidation>

// ======= Saved Searches ======= //

export const savedSearchesSortValidation = <P extends SearchesAndListsProducts>(
  product: P
) =>
  z
    .object({
      name: sortValues,
      createdAt: sortValues,
      modifiedAt: sortValues,
      countNewSignals: sortValues,
      ...(product === SpecterProducts.company && {
        countNewFundingHighlights: sortValues,
        countNewGrowthHighlights: sortValues,
      }),
      countAllSignals: sortValues,
    })
    .partial()

export const listsSortObject = (_product?: SearchesAndListsProducts) =>
  ({
    name: sortValues,
    createdAt: sortValues,
    modifiedAt: sortValues,
    _count: sortValues,

    // TODO: add logic in backend to enable these sorts
    // ...(product === SpecterProducts.company && {
    //   _countNewFundingSignals: sortValues,
    //   _countNewGrowthSignals: sortValues,
    // }),
  } as const)

export const listsSortValidation = (_product?: SearchesAndListsProducts) =>
  z.object(listsSortObject(_product)).partial().optional()

// ======= Common ======= //

export const signalsSortToOrderByInput = <P extends SpecterProducts>(
  sort: ProductSignalSort<P>
) => {
  return Object.entries(sort)
    .filter(([_field, dir]) => dir !== undefined)
    .map(([field, dir]) => {
      const keys = field.split(".")
      const result: any = {}
      let currentLevel = result

      keys.forEach((nestedKey, index) => {
        if (index === keys.length - 1) {
          currentLevel[nestedKey] = { sort: dir, nulls: "last" }
        } else {
          currentLevel[nestedKey] = currentLevel[nestedKey] || {}
          currentLevel = currentLevel[nestedKey]
        }
      })

      return result
    })
}

export const SIGNAL_SORT_VALIDATION = {
  [SpecterProducts.company]: companySignalSortValidation,
  [SpecterProducts.talent]: talentSignalSortValidation,
  [SpecterProducts.stratintel]: strategicSignalSortValidation,
  [SpecterProducts.investors]: investorsDBSortValidation,
  [SpecterProducts.fundingRounds]: fundingRoundsDBSortValidation,
  [SpecterProducts.acquisition]: acquisitionDBSortValidation,
  [SpecterProducts.ipo]: ipoDBSortValidation,
  [SpecterProducts.people]: peopleDBSortValidation,
} as const

export type ProductSignalSort<P extends SpecterProducts> = z.infer<
  (typeof SIGNAL_SORT_VALIDATION)[P]
>
